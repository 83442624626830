import "./App.css";
import React, { useState, useEffect } from "react";
import Dashboard from "./pages/Dashboard";
import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { AppContext } from "./Context/Context";
import { defaultMarkerData, userDataObject } from "./util/helper/constants";
import ProtectedRoute from "./Routes/ProtectedRoute";
import {
  getLocalStorageItem,
  reloadApp,
  setLocalStorageItem,
  clearLocalStorageItem,
  apiHeadersFormatter,
  getUserAuthPayload,
} from "./util/helper/helperFunctions";
import BackendConnectivity from "./components/BackendConnectivity/BackendConnectivity";
import { BrowserRouter } from "react-router-dom";
import { postApi } from "./util/network/getApi";
import { plantConfigUrl } from "./util/network/apiUrl";

const App = () => {
  useMsalAuthentication(InteractionType.Redirect);
  const [msalUser, setMsalUser] = useState("");
  const [accessToken, setAccessToken] = useState(null);

  //Plant Config
  const [plantConfig, setPlantConfig] = useState(null);

  //App Config
  const [appConfig, setAppConfig] = useState(null);

  const [locationsData, setLocationsData] = useState();
  const [locationCode, setLocationCode] = useState("");

  //Warehouse
  const [warehouseModel, setWarehouseModel] = useState(false);
  const [warehouseModelIsOpen, setWarehouseModelIsOpen] = useState(false);
  const [yardSummaryModalIsOpen, setYardSummaryModalIsOpen] = useState(false);
  const [isYardLegendsPopupOpen, setIsYardLegendsPopupOpen] = useState(false);

  //Popups
  const [customizePopup, setCustomizePopup] = useState(false);
  const [timeRangePopup, setTimeRangePopup] = useState(false);
  const [additionalDashboardModalIsOpen, setAdditionalDashboardModalIsOpen] =
    useState(false);
  const [isLegendsPopupOpen, setIsLegendsPopupOpen] = useState(false);
  const [isBlockRackModalOpen, setIsBlockRackModalOpen] = useState(false);
  const [allocatedView, setAllocatedView] = useState(false);
  const [allocatedViewLegend, setAllocatedViewLegend] = useState(false);
  const [showSaveDiscardLayout, setShowSaveDiscardLayout] = useState(false);
  const [isDefaultLayoutSelected, setIsDefaultLayoutSelected] = useState(true);
  const [activeLGVModule, setActiveLGVModule] = useState("dashboard");
  const [layoutPopup, setLayoutPopup] = useState(false);
  const [draggedItems, setDraggedItems] = useState([]);
  const [warehouseTempOrder, setWarehouseTempOrder] = useState(
    getLocalStorageItem("warehouse-layout-order")
  );
  const [wareHouseDraggedItems, setWareHouseDraggedItems] = useState([]);
  const [isMapDragEnable, setIsMapDragEnable] = useState(true);
  const [reRenderKey, setReRenderKey] = useState(1);

  //TimeMachine
  const [timeMachineControllerIsOpen, setTimeMachineControllerIsOpen] =
    useState(true);
  const [timeMachineModeIsEnabled, setTimeMachineModeIsEnabled] =
    useState(false);
  const [timeMachineTimeFrames, setTimeMachineTimeFrames] = useState({});
  const [timeMachineState, setTimeMachineState] = useState(true);
  const [timeMachineLoader, setTimeMachineLoader] = useState(true);
  const [isTimeMachineDataAvailable, setIsTimeMachineDataAvailable] =
    useState(true);
  const [currentPageCount, setCurrentPageCount] = useState();
  const [openedBatteryStationName, setOpenedBatteryStationName] =
    useState(null);

  //Heatmap
  const [isHeatmapDataAvailable, setIsHeatmapDataAvailable] = useState(true);
  const [isNoHeatMapDataModalOpen, setIsNoHeatMapDataModalOpen] =
    useState(false);
  const [isHeatmapModeEnabled, setIsHeatmapModeEnabled] = useState(false);
  const [heatmapTimeFrames, setHeatmapTimeFrames] = useState({});
  const [enableHeatmap, setEnableHeatmap] = useState(false);
  const [convertedHeatmapData, setConvertedHeatmapData] = useState(null);
  const [heatmapLoader, setHeatmapLoader] = useState(true);
  const [heatmapRef, setHeatmapRef] = useState(null);
  const [loaderText, setLoaderText] = useState("Loading Heatmap...");

  //Production Line
  const [enableProductionLine, setEnableProductionLine] = useState(true);
  const [productionLineViewIsOpen, setProductionLineViewIsOpen] =
    useState(true);
  const [lineProductionData, setLineProductionData] = useState();

  //Dock door
  const [enableDockDoor, setEnableDockDoor] = useState(true);
  const [dockDoorViewIsOpen, setDockDoorViewIsOpen] = useState(true);
  const [dockDoorMode, setDockDoorMode] = useState(false);

  //LGV
  const [lgvObjectViewIsOpen, setLgvObjectViewIsOpen] = useState(true);
  const [socketData, setSocketData] = useState();
  const [followingLgvList, setFollowingLgvList] = useState([]);

  //Removed LGV
  const [removedLgvViewIsOpen, setRemovedLgvViewIsOpen] = useState(true);

  //Destination Pointers
  const [destinationViewIsOpen, setDestinationViewIsOpen] = useState(true);
  const [destinationPointersViewIsOpen, setDestinationPointersViewIsOpen] =
    useState(false);
  const [enableDestinationPointers, setEnableDestinationPointers] =
    useState(false);

  //Logic Variables
  const [selectedMarkerData, setSelectedMarkerData] =
    useState(defaultMarkerData);
  const [selectedMarkerType, setSelectedMarkerType] = useState("Lgv");
  const [isMarkerPopupOpen, setIsMarkerPopupOpen] = useState(false);
  const [locationModal, setLocationModal] = useState(false);

  //Internet Connectivity
  const [connectionStatus, setConnectionStatus] = useState(true);

  //Labelled Map
  const [enableLabelledMap, setEnableLabelledMap] = useState(false);
  const [enableMapDragging, setEnableMapDragging] = useState(false);

  const [enableBatteryStationObj, setEnableBatteryStationObj] = useState(false);

  //Block and Rack Storage
  const [blockStorageData, setBlockStorageData] = useState({});
  const [selectedBlockRackLocationData, setSelectedBlockRackLocationData] =
    useState({});
  const [rackStorageData, setRackStorageData] = useState([]);

  //Non-Lgv Storage
  const [isNonLgvModalOpen, setIsNonLgvModalOpen] = useState(false);
  const [nonLgvData, setNonLgvData] = useState(null);

  //Modals Minimize and Maximize
  const [minimizedWebObjectsList, setMinimizedWebObjectsList] = useState({
    lgv: [],
    docks: [],
    productionLine: [],
  });

  const discardCustomizedLayout = () => {
    document.body.classList.add("no-drag");
    setShowSaveDiscardLayout(false);
    setIsMapDragEnable(true);
    setReRenderKey(Math.random());
    setIsDefaultLayoutSelected(true);
  };

  const saveCustomizedLayout = () => {
    document.body.classList.add("no-drag");
    let order = [];
    let warehouseOrder = [];
    draggedItems.forEach((item) => {
      order.push(item.key);
    });

    wareHouseDraggedItems.forEach((item) => {
      warehouseOrder.push(item.key);
    });
    if (isDefaultLayoutSelected) {
      clearLocalStorageItem("layout-order");
      clearLocalStorageItem("warehouse-layout-order");
      setWarehouseTempOrder(null);
      setReRenderKey(Math.random());
    } else {
      setLocalStorageItem("layout-order", order);
      setLocalStorageItem("warehouse-layout-order", warehouseOrder);
    }

    setShowSaveDiscardLayout(false);
    setIsMapDragEnable(true);
  };

  //Alert
  const [alertObject, setAlertObject] = useState({ type: "", payload: {} });

  //Component onmount
  useEffect(() => {
    //Check App Version from LocalStorage and keep version updated
    const appVersionCheckup = () => {
      document.body.classList.add("no-drag");
      if (getLocalStorageItem("version")) {
        if (getLocalStorageItem("version") !== process.env.REACT_APP_VERSION) {
          localStorage.clear();
          setLocalStorageItem("version", process.env.REACT_APP_VERSION);
          reloadApp();
        } else {
          setLocalStorageItem("version", process.env.REACT_APP_VERSION);
        }
      } else {
        localStorage.clear();
        setLocalStorageItem("version", process.env.REACT_APP_VERSION);
        reloadApp();
      }
    };

    //Retrieve App Feature settings for additional dashboard into localstorage and context
    const appFeatureSettingsCheckup = () => {
      if (localStorage.getItem("appSettings")) {
        let prevSwitchState = JSON.parse(localStorage.getItem("appSettings"));
        if (prevSwitchState.removedLgv == true) {
          setRemovedLgvViewIsOpen(true);
        } else {
          setRemovedLgvViewIsOpen(false);
        }
        if (prevSwitchState.productionLines == true) {
          setProductionLineViewIsOpen(true);
        } else {
          setProductionLineViewIsOpen(false);
        }
        if (prevSwitchState.dockDoors == true) {
          setDockDoorViewIsOpen(true);
        } else {
          setDockDoorViewIsOpen(false);
        }
        if (prevSwitchState.labelledMap == true) {
          setEnableLabelledMap(true);
        } else {
          setEnableLabelledMap(false);
        }
        if (prevSwitchState.batteryStationObj == true) {
          setEnableBatteryStationObj(true);
        } else {
          setEnableBatteryStationObj(false);
        }
        setDockDoorMode(prevSwitchState.dockDoorMode);
      } else {
        localStorage.setItem(
          "appSettings",
          JSON.stringify({
            productionLines: true,
            removedLgv: true,
            dockDoors: true,
            lgvObjects: true,
            destinationLines: false,
            dockDoorMode: false,
            labelledMap: false,
          })
        );
      }
    };

    //Get App Config from Cloud
    const getAppConfigFromCloud = async (auth, account) => {
      //POST API CALL
      if (account && auth) {
        let userPayload = getUserAuthPayload(account[0].idTokenClaims);
        let configResp = await postApi(
          plantConfigUrl,
          userPayload,
          apiHeadersFormatter(auth.accessToken)
        );
        if (configResp?.response && configResp?.response.status === 401) {
          //UnAuthorized
          reloadApp();
        } else {
          if (configResp?.status == 200 && configResp?.data) {
            const { config } = configResp.data;
            //Set App Config details to Context
            setAppConfig(config);
          }
        }
      }
    };

    if (msalUser) {
      let auth = getLocalStorageItem("auth");
      let account = getLocalStorageItem("account");
      getAppConfigFromCloud(auth, account);
    }

    appVersionCheckup();
    appFeatureSettingsCheckup();
  }, [msalUser]);

  const contextApiObjects = {
    warehouseModel,
    setWarehouseModel,
    timeRangePopup,
    setTimeRangePopup,
    timeMachineTimeFrames,
    setTimeMachineTimeFrames,
    timeMachineModeIsEnabled,
    setTimeMachineModeIsEnabled,
    timeMachineControllerIsOpen,
    setTimeMachineControllerIsOpen,
    timeMachineState,
    setTimeMachineState,
    activeLGVModule,
    yardSummaryModalIsOpen,
    setYardSummaryModalIsOpen,
    isYardLegendsPopupOpen,
    setIsYardLegendsPopupOpen,
    setActiveLGVModule,
    customizePopup,
    setCustomizePopup,
    layoutPopup,
    openedBatteryStationName,
    setOpenedBatteryStationName,
    setLayoutPopup,
    isDefaultLayoutSelected,
    setIsDefaultLayoutSelected,
    saveCustomizedLayout,
    showSaveDiscardLayout,
    discardCustomizedLayout,
    setShowSaveDiscardLayout,
    draggedItems,
    setDraggedItems,
    wareHouseDraggedItems,
    setWareHouseDraggedItems,
    warehouseTempOrder,
    setWarehouseTempOrder,
    reRenderKey,
    setReRenderKey,
    isMapDragEnable,
    setIsMapDragEnable,
    productionLineViewIsOpen,
    setProductionLineViewIsOpen,
    removedLgvViewIsOpen,
    setRemovedLgvViewIsOpen,
    dockDoorViewIsOpen,
    setDockDoorViewIsOpen,
    lgvObjectViewIsOpen,
    setLgvObjectViewIsOpen,
    warehouseModelIsOpen,
    setWarehouseModelIsOpen,
    additionalDashboardModalIsOpen,
    setAdditionalDashboardModalIsOpen,
    timeMachineLoader,
    setTimeMachineLoader,
    isTimeMachineDataAvailable,
    setIsTimeMachineDataAvailable,
    dockDoorMode,
    setDockDoorMode,
    isLegendsPopupOpen,
    setIsLegendsPopupOpen,
    selectedMarkerData,
    setSelectedMarkerData,
    selectedMarkerType,
    setSelectedMarkerType,
    isMarkerPopupOpen,
    setIsMarkerPopupOpen,
    enableProductionLine,
    setEnableProductionLine,
    enableBatteryStationObj,
    setEnableBatteryStationObj,
    enableDockDoor,
    setEnableDockDoor,
    socketData,
    setSocketData,
    lineProductionData,
    setLineProductionData,
    isHeatmapModeEnabled,
    setIsHeatmapModeEnabled,
    heatmapTimeFrames,
    setHeatmapTimeFrames,
    enableHeatmap,
    setEnableHeatmap,
    locationModal,
    setLocationModal,
    locationsData,
    plantConfig,
    setPlantConfig,
    setLocationsData,
    locationCode,
    setLocationCode,
    currentPageCount,
    setCurrentPageCount,
    convertedHeatmapData,
    setConvertedHeatmapData,
    isNoHeatMapDataModalOpen,
    setIsNoHeatMapDataModalOpen,
    heatmapLoader,
    setHeatmapLoader,
    heatmapRef,
    setHeatmapRef,
    loaderText,
    setLoaderText,
    isHeatmapDataAvailable,
    setIsHeatmapDataAvailable,
    destinationPointersViewIsOpen,
    setDestinationPointersViewIsOpen,
    enableDestinationPointers,
    setEnableDestinationPointers,
    connectionStatus,
    setConnectionStatus,
    enableLabelledMap,
    setEnableLabelledMap,
    isBlockRackModalOpen,
    setIsBlockRackModalOpen,
    blockStorageData,
    setBlockStorageData,
    selectedBlockRackLocationData,
    setSelectedBlockRackLocationData,
    rackStorageData,
    setRackStorageData,
    isNonLgvModalOpen,
    setIsNonLgvModalOpen,
    nonLgvData,
    setNonLgvData,
    minimizedWebObjectsList,
    setMinimizedWebObjectsList,
    alertObject,
    setAlertObject,
    followingLgvList,
    setFollowingLgvList,
    allocatedView,
    setAllocatedView,
    allocatedViewLegend,
    setAllocatedViewLegend,
    appConfig,
    setAppConfig,
  };

  return (
    <AppContext.Provider value={contextApiObjects}>
      <div
        className={`App ${activeLGVModule} yard-view-${plantConfig?.yard?.isEnabled}`}
      >
        <BrowserRouter>
          <ProtectedRoute msalUser={msalUser} setMsalUser={setMsalUser}>
            <BackendConnectivity status={connectionStatus} />
            <Dashboard />
          </ProtectedRoute>
        </BrowserRouter>
      </div>
    </AppContext.Provider>
  );
};

export default App;
