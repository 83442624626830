import { useContext, useEffect, useState } from "react";
import "../../../assets/css/BlockRack/blockRackModal.css";
import { AppContext } from "../../../Context/Context";
import {
  blockRackTableDataValidation,
  formatTimestamp,
  getBlockRackEmptyPositions,
  getBlockRackName,
  getLocalStorageItem,
  qualityStatusBadgeRenderer,
  setLocalStorageItem,
} from "../../../util/helper/helperFunctions";
import closeBtn from "../../../assets/icons/close.png";
import RackFrontViewGrid from "../../RackFrontViewGrid/RackFrontViewGrid";

const BlockRackModal = ({ location }) => {
  const { setIsBlockRackModalOpen } = useContext(AppContext);
  const [locationData, setLocationData] = useState([]);
  const [modalLoader, setModalLoader] = useState(true);
  const [locationMetaData, setLocationMetaData] = useState({});
  const [modalType, setModalType] = useState();

  const closeModalHandler = () => {
    setIsBlockRackModalOpen(false);
    document.body.classList.remove("prevent-scroll");
  };

  document.addEventListener("click", (e) => {
    if (e.target === document.getElementById("lgv-wrapper-id")) {
      closeModalHandler();
    }
  });

  /**Params - storage location Data of any block/rack location selected
   * Objective - 1. To Create a List of locations from the Block/Rack Locations Object
   *             2. To Set the Basic Meta data of Block/Rack Location to state variable
   * Output - List(Array) with Block/Rack Location Data
   */
  const formatLocationObjectToArray = (locationObjectData) => {
    let locationList = [];
    let blockRackType = getLocalStorageItem("blockRackType");
    //Get the First Object of the location
    let firstLocationObjectAvailable = Object.keys(locationObjectData)[0];
    if (blockRackType) {
      //Rack
      //Get the Basic Meta data of the rack location from the first Object available in the locations Object
      const {
        totalPallets,
        emptyPalletPositions,
        storageDisabled,
        retrievalDisabled,
        locationType,
        location,
      } = locationObjectData[firstLocationObjectAvailable];
      //Setting the basic meta data to the state variable
      setLocationMetaData({
        totalPallets: totalPallets,
        emptyPositions: emptyPalletPositions,
        storageDisabled: storageDisabled,
        retrievalDisabled: retrievalDisabled,
        locationType: locationType,
        location: location,
      });
      //Push all the location's data to the locationList array
      Object.values(locationObjectData).forEach((location, index) => {
        locationList.push(location);
      });
    } else {
      //Block
      //Get the Basic Meta data of the block location from the first Object available in the locations Object
      const {
        totalPallets,
        emptyPalletPositions,
        storageDisabled,
        retrievalDisabled,
        locationType,
        location,
      } = locationObjectData[firstLocationObjectAvailable][1][0];
      //Setting the basic meta data to the state variable
      setLocationMetaData({
        totalPallets: totalPallets,
        emptyPositions: emptyPalletPositions,
        storageDisabled: storageDisabled,
        retrievalDisabled: retrievalDisabled,
        locationType: locationType,
        location: location,
      });
      //Push all the location's pallet level data to the locationList array
      Object.values(locationObjectData).forEach((location) => {
        Object.values(location).forEach((pallet) => {
          pallet.forEach((level) => {
            locationList.push(level);
          });
        });
      });
    }
    return locationList;
  };

  useEffect(() => {
    document.body.classList.add("prevent-scroll");
    if (Object.keys(location) !== 0) {
      //   setLocationData(location);
      let formattedLocationData = formatLocationObjectToArray(location);
      if (formattedLocationData.length !== 0) {
        setLocationData(formattedLocationData);
        setLocalStorageItem(
          "blockRackLocationTouched",
          formattedLocationData[0].location
        );
        setModalLoader(false);
      }
    }
    let modalType = getLocalStorageItem("blockRackType");
    if (modalType && modalType !== "") {
      setModalType(modalType);
    }
  }, []);

  const renderBlockTable = (tableData) => {
    if (tableData && tableData.length !== 0) {
      return tableData.map((block, index) => {
        if (
          blockRackTableDataValidation(block.sku) !== "null" &&
          blockRackTableDataValidation(block.lpn) !== "null"
        ) {
          return (
            <tr key={index} className="block-table-row-block-rack">
              <td className="block-table-data-block">
                {blockRackTableDataValidation(block.lpn)}
              </td>
              <td className="block-table-data-block block-table-data-lg">
                {blockRackTableDataValidation(block.sku)}
              </td>
              <td className="block-table-data-block block-table-data-md">
                {blockRackTableDataValidation(block.lot)}
              </td>
              <td className="block-table-data-block">
                {blockRackTableDataValidation(block.quantity)}
              </td>
              <td className="block-table-data-block">
                <div
                  className={`quality ${qualityStatusBadgeRenderer(
                    block.qualityStatus !== "" ? block.qualityStatus : "null"
                  )}`}
                >
                  {blockRackTableDataValidation(block.qualityStatus)}
                </div>
              </td>
              <td className="block-table-data-block">
                <h5
                  // data-testid="retreivalEnabled"
                  className={`${
                    block.positionRetrievalDisabled === "Yes"
                      ? "success-green-font"
                      : "error-red-font"
                  }`}
                >
                  {block.positionRetrievalDisabled === "Yes" ? "Yes" : "No"}
                </h5>
              </td>
              <td className="block-table-data-block">
                <h5
                  data-testid="retreivalBlocked"
                  className={`${
                    block.positionRetrievalBlocked === "Yes"
                      ? "success-green-font"
                      : "error-red-font"
                  }`}
                >
                  {block.positionRetrievalBlocked === "Yes" ? "Yes" : "No"}
                </h5>
              </td>
              <td className="block-table-data-block block-table-data-lg">
                {block.productionDate !== null
                  ? formatTimestamp(
                      new Date(block.productionDate).toUTCString()
                    )
                  : "null"}
              </td>
              <td className="block-table-data-block block-table-data-lg">
                {block.expirationDate !== null
                  ? formatTimestamp(
                      new Date(block.expirationDate).toUTCString()
                    )
                  : "null"}
              </td>
              <td className="block-table-data-block">{block.palletPosition}</td>
              <td className="block-table-data-block">
                {blockRackTableDataValidation(block.row)}
              </td>
              <td className="block-table-data-block">
                {blockRackTableDataValidation(block.palletLevel)}
              </td>
            </tr>
          );
        }
      });
    }
  };

  const renderEmptyPallet = () => {
    if (locationData.length === getBlockRackEmptyPositions(locationData)) {
      return (
        <div className="empty-row">
          <p>All Positions are Empty</p>
        </div>
      );
    }
  };

  const renderRackFrontView = (locationData) => {
    return locationData.map((pallet, index) => {
      return (
        <div
          data-testid="rack-grid"
          key={index}
          className="rack-row-block-rack"
        >
          <div className="rack-details-block-rack">
            <p className="rack-details-title-block-rack">LPN</p>
            <p className="rack-details-value-block-rack">
              {blockRackTableDataValidation(pallet.lpn)}
            </p>
          </div>
          <div className="rack-details-block-rack">
            <p className="rack-details-title-block-rack">SKU</p>
            <p className="rack-details-value-block-rack">
              {blockRackTableDataValidation(pallet.sku)}
            </p>
          </div>
          <div className="rack-details-block-rack">
            <p className="rack-details-title-block-rack">Quantity</p>
            <p className="rack-details-value-block-rack">
              {blockRackTableDataValidation(pallet.quantity)}
            </p>
          </div>
        </div>
      );
    });
  };

  const getBlockOrRackDataMetaData = (data, type, blockData, rackData) => {
    if (type == "rack") {
      if (data == "totalPallets") {
        if (rackData.length !== 0) {
          return rackData.length;
        } else {
          return "null";
        }
      } else {
        return getBlockRackEmptyPositions(rackData);
      }
    } else {
      if (data == "totalPallets") {
        return blockData.totalPallets;
      } else {
        return blockData.emptyPositions;
      }
    }
  };

  const getModalType = (modalType) => {
    if (modalType === true) {
      return "rack";
    } else {
      return "block";
    }
  };

  return (
    <div id="lgv-wrapper-id" className="popup-wrapper-block-rack">
      <div
        className={`popup-container-block-rack ${
          modalType === true ? "overflow-scroll-block-rack" : ""
        }`}
      >
        {modalLoader ? (
          <div className="block-loader">
            <h4>Loading...</h4>
            <div className="spinner"></div>
          </div>
        ) : (
          <>
            <div className="popup-header-block-rack">
              <div
                data-testid="modalTypeTitle"
                className="popup-title-block-rack"
              >
                {modalType ? "Rack Details" : "Block Details"}
                <span className="title-extra-space"></span>(
                {getBlockRackName(modalType, locationMetaData.location)})
              </div>
              <div className="popup-close-block-rack">
                <div
                  onClick={closeModalHandler}
                  className="close-btn"
                  id="close-btn"
                >
                  <img src={closeBtn} alt="close button" />
                </div>
              </div>
            </div>

            {/* Top Section */}
            <div className="popup-section-block-rack block-details-block-rack">
              <h4 className="popup-section-title-block-rack">
                {modalType ? "RACK DETAILS" : "LOCATION PALLET DATA"}
              </h4>
              {Object.values(locationMetaData).length !== 0 ? (
                <div className="details-section-block-rack">
                  <div className="details-block-rack">
                    <h5 className="details-data-title-block-rack">
                      Total Pallets
                    </h5>
                    <h5
                      data-testid="totalPalletsValue"
                      className="details-data-block-rack"
                    >
                      {getBlockOrRackDataMetaData(
                        "totalPallets",
                        getModalType(modalType),
                        locationMetaData,
                        locationData
                      )}
                    </h5>
                  </div>
                  <div className="details-block-rack">
                    <h5 className="details-data-title-block-rack">
                      Empty Positions
                    </h5>
                    <h5
                      data-testid="emptyPositionsValue"
                      className="details-data-block-rack"
                    >
                      {getBlockOrRackDataMetaData(
                        "emptyPositions",
                        getModalType(modalType),
                        locationMetaData,
                        locationData
                      )}
                    </h5>
                  </div>
                  <div className="details-block-rack">
                    <h5 className="details-data-title-block-rack">
                      Storage Enabled
                    </h5>
                    <h5
                      data-testid="storageEnabled"
                      className={`details-data-block-rack ${
                        locationMetaData.storageDisabled === 0
                          ? "success-green-font"
                          : "error-red-font"
                      }`}
                    >
                      {locationMetaData.storageDisabled === 0 ? "Yes" : "No"}
                    </h5>
                  </div>
                  <div className="details-block-rack">
                    <h5 className="details-data-title-block-rack">
                      Retrieval Enabled
                    </h5>
                    <h5
                      data-testid="retreivalEnabled"
                      className={`details-data-block-rack ${
                        locationMetaData.retrievalDisabled === 0
                          ? "success-green-font"
                          : "error-red-font"
                      }`}
                    >
                      {locationMetaData.retrievalDisabled === 0 ? "Yes" : "No"}
                    </h5>
                  </div>
                  <div className="details-block-rack">
                    <h5 className="details-data-title-block-rack">
                      Location Type
                    </h5>
                    <h5
                      data-testid="locationType"
                      className="details-data-block-rack"
                    >
                      {locationMetaData.locationType !== ""
                        ? locationMetaData.locationType
                        : "null"}
                    </h5>
                  </div>
                </div>
              ) : null}
            </div>

            {/* Table Section */}
            <div
              className={`${
                modalType === true ? "rack-split-section-block-rack" : ""
              }`}
            >
              <div
                className={`left-section-wrapper-block-rack ${
                  modalType === true ? "left-flex-block-rack" : ""
                }`}
              >
                {modalType === true ? (
                  <p className="section-title-block-rack">LPN DETAILS</p>
                ) : null}
                <div
                  className={`popup-section-block-rack block-table-block-rack ${
                    modalType === true
                      ? "rack-table-size-block-rack"
                      : "block-table-size-block-rack"
                  }`}
                >
                  <table id="block-location-table-block-rack">
                    <thead>
                      <tr className="block-table-row-block-rack">
                        <th
                          data-testid="table-header-lpn"
                          className="block-table-header-block-rack"
                        >
                          LPN
                        </th>
                        <th
                          data-testid="table-header-sku"
                          className="block-table-header-block-rack"
                        >
                          SKU
                        </th>
                        <th
                          data-testid="table-header-lot"
                          className="block-table-header-block-rack"
                        >
                          Lot
                        </th>
                        <th
                          data-testid="table-header-quantity"
                          className="block-table-header-block-rack"
                        >
                          Quantity
                        </th>
                        <th
                          data-testid="table-header-quantityStatus"
                          className="block-table-header-block-rack"
                        >
                          Quality Status
                        </th>
                        <th
                          data-testid="table-header-retrievalDisabled"
                          className="block-table-header-block-rack"
                        >
                          Retrieval Disabled
                        </th>
                        <th
                          data-testid="table-header-retrievalBlocked"
                          className="block-table-header-block-rack"
                        >
                          Retrieval Blocked
                        </th>
                        <th
                          data-testid="table-header-production"
                          className="block-table-header-block-rack"
                        >
                          Production Date
                        </th>
                        <th
                          data-testid="table-header-expiration"
                          className="block-table-header-block-rack"
                        >
                          Expiration Date
                        </th>
                        <th
                          data-testid="table-header-pallet-position"
                          className="block-table-header-block-rack"
                        >
                          Pallet Position
                        </th>
                        <th
                          data-testid="table-header-row"
                          className="block-table-header-block-rack"
                        >
                          Row
                        </th>
                        <th
                          data-testid="table-header-level"
                          className="block-table-header-block-rack"
                        >
                          Level
                        </th>
                      </tr>
                    </thead>
                    <tbody>{renderBlockTable(locationData)}</tbody>
                  </table>
                  {renderEmptyPallet()}
                </div>
              </div>
              {modalType === true && locationData.length !== 0 ? (
                <div className="right-section-wrapper-block-rack right-flex-block-rack">
                  <p className="section-title-block-rack">FRONT VIEW</p>
                  <div className="rack-table-block-rack">
                    <RackFrontViewGrid locationData={locationData} />
                  </div>
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BlockRackModal;
