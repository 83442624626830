import { useContext, useEffect, useMemo, useState } from "react";
import "../../../assets/css/LgvPopup/LgvPopup.css";
import { AppContext } from "../../../Context/Context";
import { setLocalStorageItem } from "../../../util/helper/helperFunctions";
import closeBtn from "../../../assets/icons/close.png";
import minimizeButton from "../../../assets/icons/minimize.png";
import followIcon from "../../../assets/icons/followIcon.png";
import followingIcon from "../../../assets/icons/followIconWhite.png";

import lgvGraphicsSensorsOpenFork from "../../../assets/images/lgvGraphicsSensorsOpenFork.svg";
import lgvGraphicsAlarmOpenFork from "../../../assets/images/lgvGraphicsAlarmOpenFork.svg";
import lgvGraphicsAlarmCloseFork from "../../../assets/images/lgvGraphicsAlarmCloseFork.svg";

import lgvGoodLabelImage from "../../../assets/icons/lgvLabelTick.svg";
import lgvBadLabelImage from "../../../assets/icons/lgvLabelCross.svg";

import ToggleSwitch from "../../Reusables/ToggleSwitch/ToggleSwitch";

const LgvPopup = ({ lgv }) => {
  const { isMarkerPopupOpen, setIsMarkerPopupOpen } = useContext(AppContext);
  const { socketData, setSocketData } = useContext(AppContext);
  const { minimizedWebObjectsList, setMinimizedWebObjectsList } =
    useContext(AppContext);
  const { alertObject, setAlertObject } = useContext(AppContext);
  const [minimizeInitiated, setMinimizeInitiated] = useState(false);

  const [lgvData, setLgvData] = useState(lgv);
  const { followingLgvList, setFollowingLgvList } = useContext(AppContext);
  const [isLgvBeingFollowed, setIsLgvBeingFollowed] = useState(false);

  //LGV Graphic
  const [lgvGraphicImage, setLgvGraphicImage] = useState(
    lgvGraphicsAlarmOpenFork
  );
  const [selectedLgvGraphicMode, setSelectedLgvGraphicMode] = useState("alarm");

  useEffect(() => {
    if (followingLgvList && followingLgvList.length !== 0) {
      let currentLgv = followingLgvList.find(
        (currentLgv) => currentLgv.vehicleNumber === lgvData.vehicleNumber
      );
      if (currentLgv !== undefined) {
        setIsLgvBeingFollowed(true);
      }
    }
  }, [followingLgvList]);

  const closeModalHandler = () => {
    setIsMarkerPopupOpen(false);
    document.body.classList.remove("prevent-scroll");
  };

  const minimizeModalHandler = () => {
    let currentLgvList = minimizedWebObjectsList.lgv;
    if (currentLgvList.length < 10) {
      let isLgvAvailableInList = false;
      currentLgvList.forEach((item) => {
        if (item.vehicleNumber == lgvData.vehicleNumber) {
          isLgvAvailableInList = true;
        }
      });
      if (isLgvAvailableInList == false) {
        currentLgvList.push(lgvData);
        setMinimizedWebObjectsList((minimizedWebObjectsList) => ({
          ...minimizedWebObjectsList,
          lgv: currentLgvList,
        }));
        setMinimizeInitiated(true);
        setTimeout(() => {
          closeModalHandler();
        }, 450);
      } else {
        // setAlertObject({
        //   type: "warning",
        //   payload: {
        //     heading: "LGV Already Minimized",
        //     description: "Cannot add the LGV which is already in List.",
        //   },
        // });
        closeModalHandler();
      }
    } else {
      setAlertObject({
        type: "warning",
        payload: {
          heading: "Limit reached!",
          description: "Cannot minimize more than 10 LGVs..",
        },
      });
    }
  };

  document.addEventListener("click", (e) => {
    if (e.target == document.getElementById("lgv-wrapper-id")) {
      closeModalHandler();
    }
  });

  useEffect(() => {
    if (lgvData) {
      document.body.classList.add("prevent-scroll");
      setLocalStorageItem("lgvTouched", lgvData.vehicleNumber);
    }
  }, []);

  useEffect(() => {
    if (socketData) {
      let currentLgv = socketData.find(
        (lgvData) => String(lgvData.vehicleNumber) == String(lgv.vehicleNumber)
      );
      if (currentLgv !== undefined) {
        setLgvData(currentLgv);
      }
    }
  }, [socketData, lgvData]);

  //Follow LGV Feature
  const followLgvHandler = () => {
    let existingFollowingLgvList = followingLgvList;
    let isLgvAvailableInList = false;
    existingFollowingLgvList.forEach((item) => {
      if (item.vehicleNumber == lgvData.vehicleNumber) {
        isLgvAvailableInList = true;
      }
    });
    if (isLgvAvailableInList) {
      let indexOfLgvToRemove = existingFollowingLgvList.indexOf(lgvData);
      if (indexOfLgvToRemove) {
        existingFollowingLgvList.splice(indexOfLgvToRemove, 1);
      }
      setFollowingLgvList(JSON.parse(JSON.stringify(existingFollowingLgvList)));
      setIsLgvBeingFollowed(false);
    } else {
      if (existingFollowingLgvList.length < 5) {
        existingFollowingLgvList.push(lgvData);
        setFollowingLgvList(
          JSON.parse(JSON.stringify(existingFollowingLgvList))
        );
        setIsLgvBeingFollowed(true);
      } else {
        setAlertObject({
          type: "warning",
          payload: {
            heading: "Limit reached!",
            description: "Cannot Follow more than 5 LGVs..",
          },
        });
      }
    }
  };

  const renderLgvFollowButton = useMemo(() => {
    if (isLgvBeingFollowed) {
      return (
        <div
          onClick={() => followLgvHandler()}
          className={`lgv-follow-button following`}
        >
          <img src={followingIcon} />
          <span className="following-text">Following</span>
        </div>
      );
    } else {
      return (
        <div
          onClick={() => followLgvHandler()}
          className={`lgv-follow-button un-following`}
        >
          <img src={followIcon} />
          <span className="un-following-text">Follow</span>
        </div>
      );
    }
  }, [isLgvBeingFollowed]);

  //Modal Animations
  const renderModalAnimation = () => {
    if (minimizeInitiated) {
      return "modal-animation";
    } else {
      return "";
    }
  };

  //LGV Alarm and Sensor callbacks
  const lgvAlarmCallback = () => {
    setSelectedLgvGraphicMode("alarm");
    if (lgvData.forkStatus !== undefined) {
      if (lgvData.forkStatus == "CLOSED") {
        setLgvGraphicImage(lgvGraphicsAlarmCloseFork);
      } else {
        setLgvGraphicImage(lgvGraphicsAlarmOpenFork);
      }
    }
  };

  const lgvSensorCallback = () => {
    setSelectedLgvGraphicMode("sensor");
    setLgvGraphicImage(lgvGraphicsSensorsOpenFork);
  };

  //LGV Fork open and close
  useEffect(() => {
    if (selectedLgvGraphicMode === "alarm") {
      lgvAlarmCallback();
    }
  }, [lgvData]);

  const RenderLgvGraphicModeMetaData = () => {
    if (selectedLgvGraphicMode == "alarm") {
      return (
        <>
          {lgvData.alarmName !== undefined ? (
            <div className="lgv-meta-data">
              <p className="lgv-meta-data-title">Alarm Name</p>
              <p className="lgv-meta-data-value">
                {lgvData.alarmName !== null ? lgvData.alarmName : "null"}
              </p>
            </div>
          ) : null}
          {lgvData.duration !== undefined ? (
            <div className="lgv-meta-data">
              <p className="lgv-meta-data-title">Alarm Duration (Mins)</p>
              <p className="lgv-meta-data-value">
                {lgvData.duration !== null ? lgvData.duration : "null"}
              </p>
            </div>
          ) : null}
          {lgvData.associatedAlarms !== undefined ? (
            <div className="lgv-meta-data">
              <p className="lgv-meta-data-title">Associated Alarms</p>
              <p className="lgv-meta-data-value">
                {lgvData.associatedAlarms !== null
                  ? lgvData.associatedAlarms
                  : "null"}
              </p>
            </div>
          ) : null}
        </>
      );
    } else {
      return (
        <>
          {lgvData.verticalRightPLSDirty !== undefined ? (
            <div className="lgv-meta-data">
              <p className="lgv-meta-data-title">Vertical Right PLS Dirty</p>
              <p className="lgv-meta-data-value">
                {lgvData.verticalRightPLSDirty ? "Yes" : "No"}
              </p>
            </div>
          ) : null}
          {lgvData.verticalLeftPLSDirty !== undefined ? (
            <div className="lgv-meta-data">
              <p className="lgv-meta-data-title">Vertical Left PLS Dirty</p>
              <p className="lgv-meta-data-value">
                {lgvData.verticalLeftPLSDirty ? "Yes" : "No"}
              </p>
            </div>
          ) : null}
          {lgvData.rightPLSDirty !== undefined ? (
            <div className="lgv-meta-data">
              <p className="lgv-meta-data-title">Right PLS Dirty</p>
              <p className="lgv-meta-data-value">
                {lgvData.rightPLSDirty ? "Yes" : "No"}
              </p>
            </div>
          ) : null}
          {lgvData.leftPLSDirty !== undefined ? (
            <div className="lgv-meta-data">
              <p className="lgv-meta-data-title">Left PLS Dirty</p>
              <p className="lgv-meta-data-value">
                {lgvData.leftPLSDirty ? "Yes" : "No"}
              </p>
            </div>
          ) : null}
          {lgvData.backwardPLSDirty !== undefined ? (
            <div className="lgv-meta-data">
              <p className="lgv-meta-data-title">Backward PLS Dirty</p>
              <p className="lgv-meta-data-value">
                {lgvData.backwardPLSDirty ? "Yes" : "No"}
              </p>
            </div>
          ) : null}
          {lgvData.forwardPLSDirty !== undefined ? (
            <div className="lgv-meta-data">
              <p className="lgv-meta-data-title">Forward PLS Dirty</p>
              <p className="lgv-meta-data-value">
                {lgvData.forwardPLSDirty ? "Yes" : "No"}
              </p>
            </div>
          ) : null}
          {lgvData.angle !== undefined ? (
            <div className="lgv-meta-data">
              <p className="lgv-meta-data-title">Heading Angle</p>
              <p className="lgv-meta-data-value">
                {lgvData.angle !== null ? lgvData.angle : "null"}
              </p>
            </div>
          ) : null}
          {lgvData.navQuality !== undefined ? (
            <div className="lgv-meta-data">
              <p className="lgv-meta-data-title">Navigation Quality (%)</p>
              <p className="lgv-meta-data-value">
                {lgvData.navQuality !== null ? lgvData.navQuality : "null"}
              </p>
            </div>
          ) : null}
          {lgvData.seenReflectors !== undefined ? (
            <div className="lgv-meta-data">
              <p className="lgv-meta-data-title">Reflectors Seen</p>
              <p className="lgv-meta-data-value">
                {lgvData.seenReflectors !== null
                  ? lgvData.seenReflectors
                  : "null"}
              </p>
            </div>
          ) : null}
          {lgvData.stateOfCharge !== undefined ? (
            <div className="lgv-meta-data">
              <p className="lgv-meta-data-title">State of Charge (%)</p>
              <p className="lgv-meta-data-value">
                {lgvData.stateOfCharge !== null
                  ? lgvData.stateOfCharge
                  : "null"}
              </p>
            </div>
          ) : null}
          {lgvData.telescopicPolePosition !== undefined ? (
            <div className="lgv-meta-data">
              <p className="lgv-meta-data-title">Telescopic Pole Position</p>
              <p className="lgv-meta-data-value">
                {lgvData.telescopicPolePosition !== null
                  ? lgvData.telescopicPolePosition
                  : "null"}
              </p>
            </div>
          ) : null}
        </>
      );
    }
  };

  const renderLgvAlarmStatusIcons = useMemo(() => {
    if (lgvData) {
      if (selectedLgvGraphicMode === "alarm") {
        return (
          <>
            <div className="alarm-label lgv-rspls-alarm">
              <img
                src={
                  lgvData.associatedAlarms !== null &&
                  lgvData.associatedAlarms.includes("Right Side PLS")
                    ? lgvBadLabelImage
                    : lgvGoodLabelImage
                }
              />
            </div>
            <div className="alarm-label lgv-fpls-alarm">
              <img
                src={
                  lgvData.associatedAlarms !== null &&
                  lgvData.associatedAlarms.includes("Front PLS")
                    ? lgvBadLabelImage
                    : lgvGoodLabelImage
                }
              />
            </div>
            <div className="alarm-label lgv-nav-alarm">
              <img
                src={
                  lgvData.associatedAlarms !== null &&
                  lgvData.associatedAlarms.includes("Navigation")
                    ? lgvBadLabelImage
                    : lgvGoodLabelImage
                }
              />
            </div>
            <div className="alarm-label lgv-lpls-alarm">
              <img
                src={
                  lgvData.associatedAlarms !== null &&
                  lgvData.associatedAlarms.includes("Left Side PLS")
                    ? lgvBadLabelImage
                    : lgvGoodLabelImage
                }
              />
            </div>
            <div className="alarm-label lgv-rvpls-alarm">
              <img
                src={
                  lgvData.associatedAlarms !== null &&
                  lgvData.associatedAlarms.includes("Right Vertical PLS")
                    ? lgvBadLabelImage
                    : lgvGoodLabelImage
                }
              />
            </div>
            <div className="alarm-label lgv-lvpls-alarm">
              <img
                src={
                  lgvData.associatedAlarms !== null &&
                  lgvData.associatedAlarms.includes("Left Vertical PLS")
                    ? lgvBadLabelImage
                    : lgvGoodLabelImage
                }
              />
            </div>
            <div className="alarm-label lgv-lms-alarm">
              <img
                src={
                  lgvData.associatedAlarms !== null &&
                  lgvData.associatedAlarms.includes("LMS Sensor")
                    ? lgvBadLabelImage
                    : lgvGoodLabelImage
                }
              />
            </div>
            <div className="alarm-label lgv-rpls-alarm">
              <img
                src={
                  lgvData.associatedAlarms !== null &&
                  lgvData.associatedAlarms.includes("Backward PLS")
                    ? lgvBadLabelImage
                    : lgvGoodLabelImage
                }
              />
            </div>
            <div className="alarm-label lgv-fork-alarm">
              <img
                src={
                  lgvData.associatedAlarms !== null &&
                  lgvData.associatedAlarms.includes("Fork")
                    ? lgvBadLabelImage
                    : lgvGoodLabelImage
                }
              />
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="sensor-label lgv-rspls-sensor">
              <img
                src={
                  !lgvData.rightPLSDirty ? lgvGoodLabelImage : lgvBadLabelImage
                }
              />
            </div>
            <div className="sensor-label lgv-fpls-sensor">
              <img
                src={
                  !lgvData.forwardPLSDirty
                    ? lgvGoodLabelImage
                    : lgvBadLabelImage
                }
              />
            </div>
            <div className="sensor-label lgv-lpls-sensor">
              <img
                src={
                  !lgvData.leftPLSDirty ? lgvGoodLabelImage : lgvBadLabelImage
                }
              />
            </div>
            <div className="sensor-label lgv-rvpls-sensor">
              <img
                src={
                  !lgvData.verticalRightPLSDirty
                    ? lgvGoodLabelImage
                    : lgvBadLabelImage
                }
              />
            </div>
            <div className="sensor-label lgv-nav-sensor">
              <span className="lgv-nav-sensor-text">
                {lgvData.navQuality !== undefined ? lgvData.navQuality : "0"}%
              </span>
              <div className="lgv-nav-popup">
                <div className="lgv-details">
                  <h5 className="details-data-title">Heading Angle</h5>
                  <h5 className="details-data">
                    {lgvData.angle !== null && lgvData?.angle !== undefined
                      ? lgvData.angle
                      : "null"}
                  </h5>
                </div>
                <div className="lgv-details">
                  <h5 className="details-data-title">Navigation Quality (%)</h5>
                  <h5 className="details-data">
                    {lgvData.navQuality !== null ? lgvData.navQuality : "null"}
                  </h5>
                </div>
                <div className="lgv-details">
                  <h5 className="details-data-title">Reflectors Seen</h5>
                  <h5 className="details-data">
                    {lgvData.seenReflectors !== null
                      ? lgvData.seenReflectors
                      : "null"}
                  </h5>
                </div>
                <div className="lgv-details">
                  <h5 className="details-data-title">State of Charge (%)</h5>
                  <h5 className="details-data">
                    {lgvData.stateOfCharge !== null
                      ? lgvData.stateOfCharge
                      : "null"}
                  </h5>
                </div>
                <div className="lgv-details">
                  <h5 className="details-data-title">
                    Telescopic Pole Position
                  </h5>
                  <h5 className="details-data">
                    {lgvData.telescopicPolePosition !== null
                      ? lgvData.telescopicPolePosition
                      : "null"}
                  </h5>
                </div>
              </div>
            </div>
            <div className="sensor-label lgv-lvpls-sensor">
              <img
                src={
                  !lgvData.verticalLeftPLSDirty
                    ? lgvGoodLabelImage
                    : lgvBadLabelImage
                }
              />
            </div>
            <div className="sensor-label lgv-rpls-sensor">
              <img
                src={
                  !lgvData.backwardPLSDirty
                    ? lgvGoodLabelImage
                    : lgvBadLabelImage
                }
              />
            </div>
          </>
        );
      }
    }
  }, [lgvData, selectedLgvGraphicMode]);

  return (
    <div
      id="lgv-wrapper-id"
      className={`lgv-popup-wrapper ${
        selectedLgvGraphicMode == "alarm" ? "alarm" : "sensor"
      }`}
    >
      <div className={`lgv-popup-container ${renderModalAnimation()}`}>
        <div className="popup-header">
          <div className="modal-title-section">
            <div className="popup-title">LGV Details</div>
            {renderLgvFollowButton}
          </div>
          <div className="modal-action-buttons">
            <div
              onClick={() => minimizeModalHandler()}
              className="modal-action-button-wrapper"
            >
              <img
                className="modal-action-button-no-height"
                src={minimizeButton}
              />
            </div>
            <div
              onClick={() => closeModalHandler()}
              className="modal-action-button-wrapper"
            >
              <img className="modal-action-button" src={closeBtn} />
            </div>
          </div>
        </div>
        <div className="popup-container-details-section">
          <div className="lgv-meta-data-section-wrapper">
            <div className="popup-section">
              <h4 className="popup-section-title">LGV DATA</h4>
              <div className="details-section">
                <div className="lgv-details">
                  <h5 className="details-data-title">Vehicle Number</h5>
                  <h5 className="details-data">
                    {lgvData.vehicleNumber !== null &&
                    lgvData?.vehicleNumber !== undefined
                      ? lgvData.vehicleNumber
                      : "null"}
                  </h5>
                </div>
                <div className="lgv-details">
                  <h5 className="details-data-title">Status</h5>
                  <h5 className="details-data">
                    {lgvData.status !== null && lgvData?.status !== undefined
                      ? lgvData.status
                      : "null"}
                  </h5>
                </div>
                <div className="lgv-details">
                  <h5 className="details-data-title">Source</h5>
                  <h5 className="details-data">
                    {lgvData.source !== null && lgvData?.source !== undefined
                      ? lgvData.source
                      : "null"}
                  </h5>
                </div>
                <div className="lgv-details">
                  <h5 className="details-data-title">Destination</h5>
                  <h5 className="details-data">
                    {lgvData.destination !== null &&
                    lgvData?.destination !== undefined
                      ? lgvData.destination
                      : "null"}
                  </h5>
                </div>
                <div className="lgv-details">
                  <h5 className="details-data-title">Segment</h5>
                  <h5 className="details-data">
                    {lgvData.segment !== null && lgvData?.segment !== undefined
                      ? lgvData.segment
                      : "null"}
                  </h5>
                </div>
                <div className="lgv-details">
                  <h5 className="details-data-title">X Axis</h5>
                  <h5 className="details-data">
                    {lgvData.currentPos?.lat !== null &&
                    lgvData.currentPos?.lat !== undefined
                      ? lgvData.currentPos.lat.toFixed(5)
                      : "null"}
                  </h5>
                </div>
                <div className="lgv-details">
                  <h5 className="details-data-title">Y Axis</h5>
                  <h5 className="details-data">
                    {lgvData.currentPos?.lng !== null &&
                    lgvData.currentPos?.lng !== undefined
                      ? lgvData.currentPos.lng.toFixed(5)
                      : "null"}
                  </h5>
                </div>
                <div className="lgv-details">
                  <h5 className="details-data-title">Duration</h5>
                  <h5 className="details-data">
                    {lgvData.duration !== null &&
                    lgvData?.duration !== undefined
                      ? lgvData.duration
                      : "null"}
                  </h5>
                </div>
                <div className="lgv-details">
                  <h5 className="details-data-title">Alarm Name</h5>
                  <h5 className="details-data">
                    {lgvData.alarmName !== null &&
                    lgvData?.alarmName !== undefined
                      ? lgvData.alarmName
                      : "null"}
                  </h5>
                </div>
              </div>
            </div>
            <div className="popup-section">
              <h4 className="popup-section-title">ONBOARD PALLET DATA</h4>
              <div className="details-section">
                <div className="details">
                  <h5 className="details-data-title">SKU</h5>
                  <h5 className="details-data">
                    {lgvData.sku ? lgvData.sku : "null"}
                  </h5>
                </div>
                <div className="details">
                  <h5 className="details-data-title">LPN 1</h5>
                  <h5 className="details-data">
                    {lgvData.lpn_1 ? lgvData.lpn_1 : "null"}
                  </h5>
                </div>
                <div className="details">
                  <h5 className="details-data-title">LPN 2</h5>
                  <h5 className="details-data">
                    {lgvData.lpn_2 ? lgvData.lpn_2 : "null"}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="lgv-graphic-wrapper">
            <div className="lgv-graphic-section">
              <div className="lgv-graphic-switch-section">
                <ToggleSwitch
                  alarmToggle={lgvAlarmCallback}
                  sensorToggle={lgvSensorCallback}
                />
              </div>
              <div className="lgv-graphic-image-section">
                <img src={lgvGraphicImage} />
                {renderLgvAlarmStatusIcons}
              </div>
            </div>
            <span className="seperator"></span>
            <div className="lgv-graphic-meta-data-section sensor">
              <div className="lgv-graphic-meta-data">
                <RenderLgvGraphicModeMetaData />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LgvPopup;
