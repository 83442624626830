import { useEffect, useState } from "react";
import "../../../assets/css/BlockRackTable/blockRackTable.css";
import {
  blockRackTableDataValidation,
  formatTimestamp,
} from "../../../util/helper/helperFunctions";
/**
 * Create BlockRack Table with locationData.
 * with Iterating the table data, each item is rendered as table row
 * Spinner is shown until the data is available
 * @param {locationData}
 * @returns
 */
const BlockRackTable = ({ locationData }) => {
  const [locationDataLocal, setLocationDataLocal] = useState(locationData);
  const [spinnerText, setSpinnerText] = useState("Loading...");

  useEffect(() => {
    setLocationDataLocal(locationData);
  }, [locationData]);

  return (
    <div>
      {locationDataLocal !== null ? (
        <table
          data-testid="non-lgv-block-table"
          id="block-location-table-block-rack-hof"
        >
          <thead>
            <tr className="block-table-row-block-rack">
              <th className="block-table-header-block-rack">LPN</th>
              <th className="block-table-header-block-rack">Item</th>
              <th className="block-table-header-block-rack">Item Type</th>
              <th className="block-table-header-block-rack">Quantity</th>
              <th className="block-table-header-block-rack">Lot</th>
              <th className="block-table-header-block-rack">Production Date</th>
              <th className="block-table-header-block-rack">Expiration Date</th>
              <th className="block-table-header-block-rack">Position</th>
              <th className="block-table-header-block-rack">Row</th>
              <th className="block-table-header-block-rack">Level</th>
            </tr>
          </thead>
          <tbody>
            {locationDataLocal !== null &&
              locationDataLocal.map((block, index) => {
                console.log("lock", block);
                return (
                  <tr key={index} className="block-table-row-block-rack">
                    <td className="block-table-data-block-rack">
                      {blockRackTableDataValidation(block.lpn)}
                    </td>
                    <td className="block-table-data-block-rack">
                      {blockRackTableDataValidation(block.sku)}
                    </td>
                    <td className="block-table-data-block-rack">
                      {blockRackTableDataValidation(block.itemType)}
                    </td>
                    <td className="block-table-data-block-rack">
                      {block.quantity == null ? "null" : block.quantity}
                    </td>
                    <td className="block-table-data-block-rack">
                      {block.lot == null ? "null" : block.lot}
                    </td>
                    <td className="block-table-data-block-rack">
                      {block.productionDate !== null
                        ? formatTimestamp(
                            new Date(block.productionDate).toUTCString()
                          )
                        : "null"}
                    </td>
                    <td className="block-table-data-block-rack">
                      {block.expirationDate !== null
                        ? formatTimestamp(
                            new Date(block.expirationDate).toUTCString()
                          )
                        : "null"}
                    </td>
                    <td className="block-table-data-block-rack">
                      {block.position === null ? "null" : block.position}
                    </td>
                    <td className="block-table-data-block-rack">
                      {blockRackTableDataValidation(block.row)}
                    </td>
                    <td className="block-table-data-block-rack">
                      {blockRackTableDataValidation(block.level)}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <div className="table-loader">
          <h4>{spinnerText}</h4>
          <div className="table-spinner"></div>
        </div>
      )}
    </div>
  );
};
export default BlockRackTable;
