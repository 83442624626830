import { useEffect, useState } from "react";
import "../../assets/css/RackFrontViewGrid/rackFrontViewGridStyles.css";
import { mockPalletData } from "../../util/helper/constants";

/**
 * Taking the LocationData as parameter.
 * Rendering the FrontView of the Pallets Structure
 * @param {locationData}
 * @returns
 */
const RackFrontViewGrid = ({ locationData }) => {
  const [palletData, setPalletData] = useState({});
  useEffect(() => {
    if (locationData) {
      groupLocation(locationData);
    }
  }, [locationData]);

  const groupLocation = (locationData) => {
    let groupedData = {};
    let reversedOriginal = [...locationData].reverse();

    reversedOriginal.forEach((pallet) => {
      let locationName = pallet.location.split(".").slice(0, 3).join(".");
      if (!groupedData[locationName]) {
        groupedData[locationName] = [];
      }
      groupedData[locationName].push(pallet);
    });

    let rowLevelGroupSkeleton = {};
    for (const [key, value] of Object.entries(groupedData)) {
      if (!rowLevelGroupSkeleton[key]) {
        let palletsArray = [];
        for (let levels = 0; levels < 6; levels++) {
          let palletSkeleton = {
            location: "",
            position: 0,
            row: 0,
            level: 0,
            lpn: "",
            item: "",
            itemType: "",
            qty: 0,
            lot: "",
            productionDate: "",
            expirationDate: null,
          };
          palletsArray.push(palletSkeleton);
        }

        value.forEach((row) => {
          let rowIndex = row.row - 1;
          let rowValue = palletsArray[rowIndex];
          row.is_render = true;
          if (palletsArray[rowIndex]?.lpn?.length === 0) {
            palletsArray[rowIndex] = row;
          } else {
            palletsArray[rowIndex] = [rowValue, row];
          }
        });

        rowLevelGroupSkeleton[key] = palletsArray;
      }
    }
    let dataRequired = Object.values(rowLevelGroupSkeleton)[0];

    let indexOfLastElementToRender;

    /**
     * Divide the list in two parts [ith to nth] and [0 to nth ] index
     * ith is the index of last element to be rendered in rack modal
     * step 1 : Loop the array in reverse
     * step 2 : Loop will break at the last element (ith index )to be rendered
     * step 3 : (ith to nth) index should not render . Add a key is_render :false
     * step 4 : (0th to ith ) index should render . is_render :true
     */
    //  Traverse the array from [n-1] index to 0th index

    for (let i = dataRequired?.length - 1; i >= 0; i--) {
      if (Array.isArray(dataRequired[i])) {
        addIsRenderKey(dataRequired[i], false);
      } else {
        if (
          dataRequired[i]?.lpn ||
          dataRequired[i]?.location ||
          dataRequired[i]?.sku
        ) {
          indexOfLastElementToRender = i;
          break;
        }
        dataRequired[i] = { ...dataRequired[i], is_render: false };
      }
    }

    for (let i = 0; i <= indexOfLastElementToRender; i++) {
      if (Array.isArray(dataRequired[i])) {
        addIsRenderKey(dataRequired[i], true);
      } else {
        if (
          dataRequired[i]?.lpn ||
          dataRequired[i]?.location ||
          dataRequired[i]?.sku
        ) {
          indexOfLastElementToRender = i;
          break;
        }
        dataRequired[i] = { ...dataRequired[i], is_render: true };
      }
    }
    rowLevelGroupSkeleton[Object.keys(rowLevelGroupSkeleton)[0]] = dataRequired;
    setPalletData(rowLevelGroupSkeleton);
  };

  const addIsRenderKey = (dataRequired, is_render) => {
    for (let i = dataRequired?.length - 1; i >= 0; i--) {
      if (
        dataRequired[i]?.lpn ||
        dataRequired[i]?.location ||
        dataRequired[i]?.sku
      ) {
        break;
      }
      dataRequired[i] = { ...dataRequired[i], is_render };
    }
  };

  return (
    <div className="right-section-wrapper-block-rack-hof">
      <div
        data-testid="non-lgv-rack-grid"
        className="rack-table-block-rack-hof"
      >
        {Object.values(palletData).map((row, index) => {
          return (
            <div key={index} className="rack-row-block-rack-row">
              {row.map((level, index) => {
                if (Array.isArray(level)) {
                  return (
                    <>
                      {level.map((pallet, index) => {
                        return (
                          <div key={index} className="rack-row-block-rack-hof">
                            <p>{pallet.location}</p>
                            <div className="rack-details-block-rack-hof">
                              <p className="rack-details-title-block-rack-hof">
                                LPN
                              </p>
                              <p className="rack-details-value-block-rack-hof">
                                {pallet.lpn == "" ? "" : pallet.lpn}
                              </p>
                            </div>
                            <div className="rack-details-block-rack-hof">
                              <p className="rack-details-title-block-rack-hof">
                                SKU
                              </p>
                              <p className="rack-details-value-block-rack-hof">
                                {pallet.sku == "" ? "" : pallet.sku}
                              </p>
                            </div>
                            <div className="rack-details-block-rack-hof">
                              <p className="rack-details-title-block-rack-hof">
                                Quantity
                              </p>
                              <p className="rack-details-value-block-rack-hof">
                                {pallet.quantity !== null
                                  ? pallet.quantity
                                  : ""}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  );
                } else {
                  if (
                    level?.location?.length == 0 ||
                    level?.lpn?.length == 0 ||
                    level?.sku?.length == 0
                  ) {
                    return (
                      <div
                        key={index}
                        className="rack-row-block-rack-hof rack-row-block-rack-hof-hidden"
                        style={{
                          display: `${!level?.is_render ? "none" : "block"}`,
                        }}
                      >
                        <p>{mockPalletData.location}</p>
                        <div className="rack-details-block-rack-hof">
                          <p className="rack-details-title-block-rack-hof">
                            LPN
                          </p>
                          <p className="rack-details-value-block-rack-hof">
                            {mockPalletData.lpn == "" ? "" : mockPalletData.lpn}
                          </p>
                        </div>
                        <div className="rack-details-block-rack-hof">
                          <p className="rack-details-title-block-rack-hof">
                            SKU
                          </p>
                          <p className="rack-details-value-block-rack-hof">
                            {mockPalletData.sku == "" ? "" : mockPalletData.sku}
                          </p>
                        </div>
                        <div className="rack-details-block-rack-hof">
                          <p className="rack-details-title-block-rack-hof">
                            Quantity
                          </p>
                          <p className="rack-details-value-block-rack-hof">
                            {mockPalletData.quantity !== null
                              ? mockPalletData.quantity
                              : ""}
                          </p>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div key={index} className="rack-row-block-rack-hof">
                        <p>{level.location}</p>
                        <div className="rack-details-block-rack-hof">
                          <p className="rack-details-title-block-rack-hof">
                            LPN
                          </p>
                          <p className="rack-details-value-block-rack-hof">
                            {level.lpn == "" ? "" : level.lpn}
                          </p>
                        </div>
                        <div className="rack-details-block-rack-hof">
                          <p className="rack-details-title-block-rack-hof">
                            SKU
                          </p>
                          <p className="rack-details-value-block-rack-hof">
                            {level.sku == "" ? "" : level.sku}
                          </p>
                        </div>
                        <div className="rack-details-block-rack-hof">
                          <p className="rack-details-title-block-rack-hof">
                            Quantity
                          </p>
                          <p className="rack-details-value-block-rack-hof">
                            {level.quantity !== null ? level.quantity : ""}
                          </p>
                        </div>
                      </div>
                    );
                  }
                }
              })}
            </div>
          );

          // return (
          //   <div key={index} className="rack-row-block-rack-row">
          //     {[...row].reverse().map((level, index) => {
          //       return (
          //         <div key={index} className="rack-row-block-rack-hof">
          //           <p>{level.location}</p>
          //           <div className="rack-details-block-rack-hof">
          //             <p className="rack-details-title-block-rack-hof">LPN</p>
          //             <p className="rack-details-value-block-rack-hof">
          //               {level.lpn == "" ? "" : level.lpn}
          //             </p>
          //           </div>
          //           <div className="rack-details-block-rack-hof">
          //             <p className="rack-details-title-block-rack-hof">SKU</p>
          //             <p className="rack-details-value-block-rack-hof">
          //               {level.item == "" ? "" : level.item}
          //             </p>
          //           </div>
          //           <div className="rack-details-block-rack-hof">
          //             <p className="rack-details-title-block-rack-hof">
          //               Quantity
          //             </p>
          //             <p className="rack-details-value-block-rack-hof">
          //               {level.qty !== null ? level.qty : ""}
          //             </p>
          //           </div>
          //         </div>
          //       );
          //     })}
          //   </div>
          // );
        })}
      </div>
    </div>
  );
};

export default RackFrontViewGrid;
