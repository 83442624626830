import "../../assets/css/Map/map.css";
import L from "leaflet";
import {
  ImageOverlay,
  MapContainer,
  Marker,
  Polygon,
  Popup,
} from "react-leaflet";
import { useEffect, React, useState, useContext, useMemo, useRef } from "react";
import {
  checkIsLocationCodeValid,
  checkIsTimeMachineEnabled,
  clearLocalStorageItem,
  combineKafkaSocketAndLGVDetailsSocket,
  convertReactComponentToHtmlIcon,
  destinationPointersCoordinateCorrection,
  eachProductionLineCoordinateCorrection,
  filterDuplicateProductionLine,
  findDockDoorPosition,
  getLocalStorageItem,
  getPlantDetails,
  getScreenSize,
  isKafkaDataAvailable,
  lgvCoordinateCorrection,
  lgvNameRenderer,
  mapCoordinate,
  plantDockDoorCoordinateCorrection,
  plantProductionLineCoordinateCorrection,
  plantBatteryStationObjCoordinateCorrection,
  reloadApp,
  removeDockDoorLayer,
  removeExistingProductionLines,
  removeLgvs,
  setDynamicZoomLevelToMap,
  setLocalStorageItem,
  blockRackLocationExceptionList,
  getNonLgvPosition,
  isBlockRackNonLgvEnabled,
  isVerticalNonLgv,
  onDragging,
  onDraggingEnd,
  replaceDotWithDash,
  renderDoublePalletLocation,
  renderSinglePalletLocation,
  renderRackPallet,
  renderDoublePalletLocationWithReverseOrder,
  findIsLocationVertical,
  preFillPalletPositionIfEmpty,
  formatName,
  getPlantConfig,
  getLocationGroupOffset,
  getDestinationPointerOffset,
  renderPreFilledSingleBlockPallet,
  renderPreFilledDoubleBlockPallet,
  getBlockRackDataWithPositionOrientation,
  getPositionOrientationOfStorage,
  setBlockStorageSize,
  getPlantImage,
  getPlantMapImageUrl,
} from "../../util/helper/helperFunctions";

import LineProduction from "../LineProduction/LineProduction";
import { AppContext } from "../../Context/Context";
import DockDoor from "../DockDoor/DockDoor";
import DockIcon from "../DockDoor/DockIcon";
import LgvIcon from "../LgvIcon/LgvIcon";
import LineProductionPopup from "../Modal/LineProductionPopup/LineProductionPopup";
import BatteryStationObject from "../BatteryStation/BatteryStationObject";
import zoomInIcon from "../../assets/icons/add.png";
import zoomOutIcon from "../../assets/icons/minus.png";
import zoomRefreshIcon from "../../assets/icons/refresh.png";
import expand from "../../assets/icons/expandDedault.png";
import closeBtn from "../../assets/icons/closeSm.png";

const IndoorMap = ({ socket }) => {
  //Context API List
  const {
    productionLineViewIsOpen,
    setProductionLineViewIsOpen,
    isMapDragEnable,
  } = useContext(AppContext);
  const {
    dockDoorViewIsOpen,
    setDockDoorViewIsOpen,
    openedBatteryStationName,
  } = useContext(AppContext);
  const { socketData, setSocketData } = useContext(AppContext);
  const { setIsMarkerPopupOpen } = useContext(AppContext);
  const { selectedMarkerData, setSelectedMarkerData } = useContext(AppContext);
  const { selectedMarkerType, setSelectedMarkerType } = useContext(AppContext);
  const {
    enableProductionLine,
    setEnableProductionLine,
    enableBatteryStationObj,
  } = useContext(AppContext);
  const { timeMachineModeIsEnabled, setTimeMachineModeIsEnabled } =
    useContext(AppContext);
  const { locationCode, setLocationCode } = useContext(AppContext);
  const { lgvObjectViewIsOpen, setLgvObjectViewIsOpen } =
    useContext(AppContext);
  const { enableDockDoor, setEnableDockDoor } = useContext(AppContext);
  const { isNoHeatMapDataModalOpen, setIsNoHeatMapDataModalOpen } =
    useContext(AppContext);
  const { destinationPointersViewIsOpen, setDestinationPointersViewIsOpen } =
    useContext(AppContext);
  const { enableDestinationPointers, setEnableDestinationPointers } =
    useContext(AppContext);
  const { connectionStatus, setConnectionStatus } = useContext(AppContext);
  const { enableLabelledMap, setEnableLabelledMap } = useContext(AppContext);
  const { blockStorageData, setBlockStorageData } = useContext(AppContext);
  const { rackStorageData, setRackStorageData } = useContext(AppContext);
  const { selectedBlockRackLocationData, setSelectedBlockRackLocationData } =
    useContext(AppContext);
  const { isBlockRackModalOpen, setIsBlockRackModalOpen } =
    useContext(AppContext);
  const { isNonLgvModalOpen, setIsNonLgvModalOpen } = useContext(AppContext);
  const { nonLgvData, setNonLgvData } = useContext(AppContext);
  const { minimizedWebObjectsList, setMinimizedWebObjectsList } =
    useContext(AppContext);
  const { followingLgvList, setFollowingLgvList } = useContext(AppContext);

  //Plant Config
  const { plantConfig, setPlantConfig } = useContext(AppContext);

  // //Plant Image Config
  const { appConfig, setAppConfig } = useContext(AppContext);

  //Hooks List
  const [mapLoader, setMapLoader] = useState(true);
  const [convertedSocketData, setConvertedSocketData] = useState();
  const [convertedDoorDockData, setConvertedDockDoorData] = useState();
  const [convertedProductionLineData, setConvertedProductionLineData] =
    useState();
  const [convertedBatteryStationObjData, setConvertedBatteryStationObjData] =
    useState();
  const [mapObject, setMapObject] = useState();
  const [isMapDraggable, setIsMapDraggable] = useState(false);
  const [dockDoorMode, setDockDoorMode] = useState(false);
  const [groupedDockData, setGroupedDockData] = useState({});
  const [groupedArrayData, setGroupedArrayData] = useState();
  const [convertedCenter, setConvertedCenter] = useState();
  const [zoomLevel, setZoomLevel] = useState(0);
  const [isLgvListOpened, setIsLgvListOpened] = useState(false);
  const [isDockListOpened, setIsDockListOpened] = useState(false);
  const [isProductionLineListOpened, setIsProductionLineListOpened] =
    useState(false);
  const [isLgvFollowingListOpened, setIsLgvFollowingListOpened] =
    useState(false);

  //ProductionLine
  const [localProductionLineData, setLocalProductionLineData] = useState([]);

  const [localBatteryStationObjData, setLocalBatteryStationObjData] = useState(
    []
  );

  //Dock Doors
  const [localDockDoorData, setLocalDockDoorData] = useState([]);

  const mapRef = useRef(null);
  //Customize dashboard switch settings
  useEffect(() => {
    if (localStorage.getItem("appSettings")) {
      let prevSwitchState = JSON.parse(localStorage.getItem("appSettings"));
      if (prevSwitchState.productionLines === true) {
        setProductionLineViewIsOpen(true);
      }
      if (prevSwitchState.dockDoors === true) {
        setDockDoorViewIsOpen(true);
      }
    }
    setLocalStorageItem("heatmapTotalPageCount", 0);
    setLocalStorageItem("heatmapCurrentPageCount", 1);
    setLocalStorageItem("existingHeatmap", []);
    setLocalStorageItem(
      "plantDetails",
      getPlantDetails(getLocalStorageItem("locationCode"), "indoorMap")
    );
    setLocalStorageItem(
      "mapOverlayDetails",
      getPlantDetails(getLocalStorageItem("locationCode"), "mapOverlay")
    );
    setLocalStorageItem(
      "plantHeatMapDetails",
      getPlantDetails(getLocalStorageItem("locationCode"), "heatmap")
    );
  }, []);

  useEffect(() => {
    mapRef.current?.dragging[isMapDragEnable ? "enable" : "disable"]();
  }, [isMapDragEnable]);

  //Set location code on plant change
  useEffect(() => {
    setLocalStorageItem(
      "plantDetails",
      getPlantDetails(getLocalStorageItem("locationCode"), "indoorMap")
    );
    setLocalStorageItem(
      "mapOverlayDetails",
      getPlantDetails(getLocalStorageItem("locationCode"), "mapOverlay")
    );
    setLocalStorageItem(
      "plantHeatMapDetails",
      getPlantDetails(getLocalStorageItem("locationCode"), "heatmap")
    );
  }, [getLocalStorageItem("locationCode")]);

  // useEffect(() => {
  //   const keyPressHandler = (event) => {
  //     if(event.key === 'k') {
  //       event.preventDefault();
  //       setZoomInPressedCount(zoomInPressedCount + 1);
  //       console.log(cursorPosition);
  //       zoomInHotPress(zoomInPressedCount, cursorPosition);
  //     }
  //     if(event.key === 'l') {
  //       event.preventDefault();
  //       setZoomOutPressedCount(zoomOutPressedCount + 1);
  //       console.log(zoomOutPressedCount);
  //       // zoomOut(zoomOutPressedCount);
  //     }
  //   }
  //   document.addEventListener('keydown', keyPressHandler);
  //   return () => {
  //     document.removeEventListener('keydown', keyPressHandler);
  //   };
  // }, []);

  //Format Raw LGV data to Marker data set
  const lgvJsonFormatter = (data, socket) => {
    let jsonArray = [];
    if (data && data !== "Error connecting to datasource!") {
      let filteredData = data?.filter((item) => {
        return item.longitude !== null && item.latitude != null;
      });
      if (filteredData.length !== 0) {
        filteredData.forEach((dataValue) => {
          let formattedJson = {
            destination: "",
            source: "",
            duration: "",
            lgvName: "",
            lpn: "",
            segment: "",
            sku: "",
            status: "",
            vehicleNumber: "",
            currentPos: {
              lat: "",
              lng: "",
            },
            path: [],
          };

          Object.entries(dataValue).forEach(([key, value]) => {
            formattedJson[key] = dataValue[key];
          });
          formattedJson.currentPos["lat"] = dataValue.latitude;
          formattedJson.currentPos["lng"] = dataValue.longitude;
          jsonArray.push(formattedJson);
        });
        return jsonArray;
      }
    }
  };

  //Convert LGV Coordinate from Ignition system to LGV One system
  const lgvCoordinateConverter = () => {
    try {
      if ((appConfig, plantConfig, mapObject)) {
        const { lgvs } = plantConfig.indoorMap;
        const { bounds } = lgvs;

        socketData.forEach((marker) => {
          let convertedCoordinates = mapCoordinate(
            marker["longitude"],
            marker["latitude"],
            "lgv",
            bounds
          );
          let convertedPoints = L.point(
            convertedCoordinates.x,
            convertedCoordinates.y
          );
          let convertedLatLng =
            mapObject.map.target.layerPointToLatLng(convertedPoints);
          marker.currentPos = {
            lat: convertedLatLng.lat,
            lng: convertedLatLng.lng,
          };

          let destinationConvertedCoordinates = mapCoordinate(
            marker["destinationLongitude"],
            marker["destinationLatitude"],
            "lgv",
            bounds
          );
          let destinationConvertedPoints = L.point(
            destinationConvertedCoordinates.x,
            destinationConvertedCoordinates.y
          );
          let destinationConvertedLatLng =
            mapObject.map.target.layerPointToLatLng(destinationConvertedPoints);
          marker.destinationCurrentPos = {
            lat: destinationConvertedLatLng.lat,
            lng: destinationConvertedLatLng.lng,
          };
        });
        setConvertedSocketData(socketData);
        setMapLoader(false);
      }
    } catch (err) {}
  };

  //Convert Dock door Coordinate from Ignition system to LGV One system
  const dockDoorCoordinateConverter = () => {
    if (plantConfig && mapObject && groupedArrayData) {
      let { dockDoors } = plantConfig.indoorMap;
      groupedArrayData.forEach((dockData) => {
        let convertedCoordinates = mapCoordinate(
          dockData[0]["LONGITUDE"],
          dockData[0]["LATITUDE"],
          "dockdoors",
          dockDoors.bounds
        );

        let coordinateCorrection = dockDoors.groupOffset;
        let convertedPoints = L.point(
          convertedCoordinates.x + coordinateCorrection.x,
          convertedCoordinates.y + coordinateCorrection.y
        );
        let convertedLatLng =
          mapObject.map.target.layerPointToLatLng(convertedPoints);
        dockData.forEach((pallet) => {
          pallet.currentPos = {
            lat: convertedLatLng.lat,
            lng: convertedLatLng.lng,
          };
        });
      });
      // setMapLoader(false);
      setConvertedDockDoorData(groupedArrayData);
    }
  };

  //Convert Production line Coordinate from Ignition system to LGV One system
  const BatteryStationObjCoordinateConverter = () => {
    if (
      plantConfig &&
      mapObject &&
      localBatteryStationObjData !== "Error connecting to datasource!"
    ) {
      const { batteryStations } = plantConfig.indoorMap;
      const { bounds, groupOffset } = batteryStations;
      const correctionUnits = groupOffset;
      localBatteryStationObjData.forEach((data, index) => {
        let convertedCoordinates = mapCoordinate(
          data["longitude"],
          data["latitude"],
          "battery",
          bounds
        );
        let convertedPoints = L.point(
          convertedCoordinates.x - correctionUnits.x,
          convertedCoordinates.y - correctionUnits.y
        );
        let convertedLatLng =
          mapObject.map.target.layerPointToLatLng(convertedPoints);
        data.currentPos = {
          lat: convertedLatLng.lat,
          lng: convertedLatLng.lng,
        };
      });
      // setMapLoader(false);
      setConvertedBatteryStationObjData(localBatteryStationObjData);
    }
  };

  //Convert Production Line Coordinate from Ignition system to LGV One system
  const productionLineCoordinateConverter = () => {
    if (
      plantConfig &&
      mapObject &&
      localProductionLineData !== "Error connecting to datasource!"
    ) {
      //Get Plant Config data
      let { productionLines } = plantConfig.indoorMap;

      localProductionLineData.forEach((data, index) => {
        let convertedCoordinates = mapCoordinate(
          data["LONGITUDE"],
          data["LATITUDE"],
          "lines",
          productionLines.bounds
        );
        let convertedPoints = L.point(
          convertedCoordinates.x - productionLines.groupOffset.x,
          convertedCoordinates.y - productionLines.groupOffset.y
        );
        let convertedLatLng =
          mapObject.map.target.layerPointToLatLng(convertedPoints);
        data.currentPos = {
          lat: convertedLatLng.lat,
          lng: convertedLatLng.lng,
        };
      });
      // setMapLoader(false);
      setConvertedProductionLineData(localProductionLineData);
    }
  };

  //Move Map to the center of view
  const centerMapView = (unit1, unit2) => {
    let convertedCoordinates;
    if (unit1 && unit2) {
      convertedCoordinates = mapCoordinate(unit1, unit2);
    } else {
      convertedCoordinates = mapCoordinate(275000, 70000);
    }
    let convertedPoints = L.point(
      convertedCoordinates.x,
      convertedCoordinates.y
    );
    let convertedLatLng =
      mapObject.map.target.layerPointToLatLng(convertedPoints);
    mapObject.map.target.setView([convertedLatLng.lat, convertedLatLng.lng]);
    setConvertedCenter(convertedLatLng);
  };

  //Get LGV data on Page load from socket and combine if needed with Kafka
  useEffect(() => {
    let auth = JSON.parse(localStorage.getItem("auth"));
    let locationCodeLocal = JSON.parse(localStorage.getItem("locationCode"));
    try {
      const initiateLgvDetailWebSocket = () => {
        if (!isKafkaDataAvailable()) {
          //---------------------LGV TABLE SOCKET START---------------------//
          socket.on(`${locationCodeLocal}_LGV_DETAILS`, (data) => {
            if (
              data.results.length !== 0 &&
              data.results !== "Error connecting to datasource!"
            ) {
              setSocketData(
                JSON.parse(JSON.stringify(lgvJsonFormatter(data.results)))
              );
              lgvCoordinateConverter();
              setConnectionStatus(true);
            } else {
              initiateLgvDetailWebSocket();
            }
          });
        } else {
          //Get LGV data from LGV DETAILS socket
          //---------------------LGV TABLE SOCKET START---------------------//
          socket.on(`${locationCodeLocal}_LGV_DETAILS`, (data) => {
            if (
              data.results.length !== 0 &&
              data.results !== "Error connecting to datasource!"
            ) {
              setSocketData(
                JSON.parse(JSON.stringify(lgvJsonFormatter(data.results)))
              );
              lgvCoordinateConverter();
              setConnectionStatus(true);
              //Get LGV data from LGV KAFKA socket
              //---------------------LGV KAFKA SOCKET START---------------------//
              socket.on(`${locationCodeLocal}_LGV_POSITION`, (kafkaData) => {
                if (
                  kafkaData.results.length !== 0 &&
                  kafkaData.results !== "Error connecting to datasource!"
                ) {
                  let filteredData = kafkaData.results.filter(
                    (lgv) =>
                      lgv.latitude !== "undefined" &&
                      lgv.longitude !== "undefined"
                  );
                  let combinedData = combineKafkaSocketAndLGVDetailsSocket(
                    filteredData,
                    data.results
                  );
                  setSocketData(lgvJsonFormatter(combinedData));
                  lgvCoordinateConverter();
                } else {
                  initiateLgvDetailWebSocket();
                }
              });
              //---------------------KAFKA SOCKET END---------------------//
            } else {
              initiateLgvDetailWebSocket();
            }
            //---------------------LGV TABLE SOCKET END---------------------//
          });
        }
      };

      const initiateLineProductionWebSocket = () => {
        socket.on(`${locationCodeLocal}_PRODUCTION_DETAILS`, (data) => {
          if (data.results && data.results !== undefined) {
            setLocalProductionLineData(data.results);
          }
        });
      };

      const initiateBatteryStationWebSocket = () => {
        socket.on(`${locationCodeLocal}_BATTERY_STATION_DETAILS`, (data) => {
          if (data.results && data.results !== undefined) {
            setLocalBatteryStationObjData(data.results);
          }
        });
      };

      const initiateSimpleDockDoorSocketChannel = () => {
        socket.on(`${locationCodeLocal}_DOCK_DETAILS_SIMPLE`, (response) => {
          if (response) {
            if (
              response.results.length !== 0 ||
              response.results !== "Error connecting to datasource!"
            ) {
              setLocalDockDoorData(response.results);
            }
          }
        });
      };

      const initiateComplexDockDoorSocketChannel = () => {
        socket.on(`${locationCodeLocal}_DOCK_DETAILS_COMPLEX`, (response) => {
          if (response) {
            if (
              response.results.length !== 0 ||
              response.results !== "Error connecting to datasource!"
            ) {
              setLocalDockDoorData(response.results);
            }
          }
        });
      };

      if (
        auth !== null &&
        socket &&
        !checkIsTimeMachineEnabled() &&
        checkIsLocationCodeValid(locationCode, locationCodeLocal)
      ) {
        initiateLineProductionWebSocket();
        initiateBatteryStationWebSocket();
        initiateLgvDetailWebSocket();
        let dockMode = !JSON.parse(localStorage.getItem("dockMode")) ? 1 : 0;
        if (dockMode == 0) {
          initiateComplexDockDoorSocketChannel();
        } else {
          initiateSimpleDockDoorSocketChannel();
        }
      }
    } catch (err) {
      // console.log(err);
    }
  }, [
    socket,
    localStorage.getItem("locationCode"),
    localStorage.getItem("locationSelected"),
  ]);

  //Convert LGV, dock Door and Production Line coordinates if data available
  useEffect(() => {
    if (socketData) {
      lgvCoordinateConverter();
    }
    if (groupedArrayData) {
      dockDoorCoordinateConverter();
    }
    if (localProductionLineData) {
      productionLineCoordinateConverter();
    }
    if (localBatteryStationObjData) {
      BatteryStationObjCoordinateConverter();
    }
  }, [
    socketData,
    groupedArrayData,
    localProductionLineData,
    localBatteryStationObjData,
  ]);

  //Adjust the Map to the screen
  useEffect(() => {
    if (mapObject) {
      let screenSize = getScreenSize();
      if (screenSize >= 1440 && screenSize < 1800) {
        setTimeout(() => {
          centerMapView();
          setDynamicZoomLevelToMap("1.0", "indoor");
        }, 1000);
      } else if (screenSize >= 1800 && screenSize < 2500) {
        setTimeout(() => {
          centerMapView(275000, 75000);
          setDynamicZoomLevelToMap("1.0", "indoor");
        }, 500);
      } else if (screenSize >= 2560 && screenSize < 3000) {
        setTimeout(() => {
          centerMapView(280000, 75000);
          setDynamicZoomLevelToMap("1.0", "indoor");
        }, 500);
      } else if (screenSize >= 3000 && screenSize < 4000) {
        setTimeout(() => {
          centerMapView(312000, 60000);
          setDynamicZoomLevelToMap("1.4", "indoor");
        }, 500);
      } else if (screenSize >= 4000 && screenSize < 5000) {
        setTimeout(() => {
          centerMapView(350000, 40000);
          setDynamicZoomLevelToMap("1.4", "indoor");
        }, 500);
      } else if (screenSize >= 5000 && screenSize < 6000) {
        setTimeout(() => {
          centerMapView(312000, 60000);
          setDynamicZoomLevelToMap("2.0", "indoor");
        }, 500);
      } else {
        centerMapView();
      }
      mapObject.map.target.doubleClickZoom.disable();
    }
  }, [mapObject, window.innerWidth]);

  //Remove Dock door if disabled
  useEffect(() => {
    if (dockDoorViewIsOpen === false) {
      removeDockDoorLayer();
      setEnableDockDoor(false);
    }
  }, [dockDoorViewIsOpen]);

  //Remove Production Line if disabled
  useEffect(() => {
    if (productionLineViewIsOpen === false) {
      removeExistingProductionLines();
      setEnableProductionLine(false);
    }
  }, [productionLineViewIsOpen]);

  //Remove Lgv if disabled
  useEffect(() => {
    if (lgvObjectViewIsOpen === false) {
      removeLgvs();
      setLocalStorageItem("enableLgvMarkers", false);
    }
  }, [lgvObjectViewIsOpen]);

  //Group Raw dock door pallet data to dock door groups
  useEffect(() => {
    if (
      plantConfig &&
      localDockDoorData &&
      localDockDoorData !== "Error connecting to datasource!" &&
      mapObject
    ) {
      let dockDoorPosition = findDockDoorPosition(plantConfig);
      setGroupedDockData({});
      localDockDoorData.forEach((data) => {
        const groupName = data["DOCK"];
        if (!groupedDockData[groupName]) {
          groupedDockData[groupName] = [];
        }
        groupedDockData[groupName].push(data);
        let groupedData = Object.values(groupedDockData);
        if (dockDoorPosition === "DOWN") {
          groupedData.forEach((data, index) => {
            let reversedGroupedData = [];
            for (let i = data.length - 1; i >= 0; i--) {
              reversedGroupedData.push(data[i]);
            }
            groupedData[index] = reversedGroupedData;
          });
        }
        setGroupedArrayData(groupedData);
      });
    }
  }, [localDockDoorData, dockDoorMode]);

  useEffect(() => {
    setDockDoorMode(JSON.parse(localStorage.getItem("dockMode")));
  }, [localStorage.getItem("dockMode")]);

  //Set Map Object instance once leaflet map is ready and initiated
  const mapWhenReadyHandler = (map) => {
    setMapObject({ map });
  };

  //LGV, Dock door and production line Modal toggler
  useEffect(() => {
    if (selectedMarkerType === "lgv") {
      let touchedLgv = getLocalStorageItem("lgvTouched");
      if (touchedLgv && convertedSocketData) {
        let currentLgv = convertedSocketData.find(
          (lgv) => lgv.vehicleNumber === touchedLgv
        );
        setSelectedMarkerData(currentLgv);
      }
    } else if (selectedMarkerType === "dock") {
      if (convertedDoorDockData.length > 25) {
        let touchedDockDoor = getLocalStorageItem("dockDoorTouched");
        if (touchedDockDoor && convertedDoorDockData) {
          let currentDockData = convertedDoorDockData.find(
            (data) => data[0].DOCK === touchedDockDoor
          );
          setSelectedMarkerData(currentDockData);
        }
      }
    } else if (selectedMarkerType === "productionLine") {
      let touchedProductionLine = getLocalStorageItem("productionLineTouched");
      if (touchedProductionLine && convertedProductionLineData) {
        let currentProductionLine = convertedProductionLineData.find(
          (production) => production.LINE_NAME === touchedProductionLine
        );
        setSelectedMarkerData(currentProductionLine);
      }
    }
  }, [convertedSocketData, convertedDoorDockData, convertedProductionLineData]);

  //Render LGV Markers on the map

  const RenderLgv = useMemo(() => {
    if (
      plantConfig &&
      convertedSocketData &&
      getLocalStorageItem("enableLgvMarkers")
    ) {
      //Get Plant's LGV Config
      const { lgvs } = plantConfig.indoorMap;
      //LGV's Marker and Group offset values
      const { marker, groupOffset } = lgvs;
      return convertedSocketData.map((lgvMarker, index) => {
        let isLgvBeingFollowed = followingLgvList.find(
          (lgv) => lgv.vehicleNumber == lgvMarker.vehicleNumber
        );
        let markerData = lgvMarker;
        lgvNameRenderer(markerData.vehicleNumber);
        let reactIcon = convertReactComponentToHtmlIcon(
          <LgvIcon
            data={lgvNameRenderer(markerData.vehicleNumber)}
            status={markerData.status}
            isFollowing={isLgvBeingFollowed == undefined ? false : true}
          />,
          "lgv-marker",
          marker.size,
          [40.5, 48]
        );
        let lgvCorrection = groupOffset;
        return (
          <Marker
            type="lgv"
            data={markerData}
            eventHandlers={{
              click: (e) => {
                setSelectedMarkerData(markerData);
                setSelectedMarkerType("lgv");
                setIsMarkerPopupOpen(true);
              },
            }}
            key={index}
            riseOnHover
            position={[
              markerData.currentPos.lat + lgvCorrection.x,
              markerData.currentPos.lng + lgvCorrection.y,
            ]}
            icon={reactIcon}
          ></Marker>
        );
      });
    }
  }, [convertedSocketData, followingLgvList, plantConfig]);

  //Render Dock door Markers on the map
  const RenderDockDoor = useMemo(() => {
    if (plantConfig && convertedDoorDockData && enableDockDoor) {
      let { dockDoors } = plantConfig.indoorMap;
      return convertedDoorDockData.map((dockMarker, index) => {
        let height = dockDoors.marker.size[1];
        let width = dockDoors.marker.size[0];
        let markerData = dockMarker;
        let isHorizontal = index > 31 && markerData[0]?.ANGLE === 0;
        if (isHorizontal) {
          let temp = height;
          height = width;
          width = temp;
        }

        let reactIcon = convertReactComponentToHtmlIcon(
          <DockIcon
            plantConfig={plantConfig}
            dockMode={dockDoorMode}
            dockData={markerData}
            index={index}
          />,
          `dock-door ${
            markerData[0].READY_FOR_LOADING == 1
              ? "ready-to-load"
              : "not-ready-to-load"
          } ${isHorizontal ? "dock-door-with-90-angle" : ""}`,
          [width, height],
          [0, 55]
        );

        let coordinateCorrection = {
          x: dockDoors?.eachItemOffset?.[markerData[0].DOCK]?.x || 0,
          y: dockDoors?.eachItemOffset?.[markerData[0].DOCK]?.y || 0,
        };

        return (
          <Marker
            type="dock"
            data={markerData}
            eventHandlers={{
              click: (e) => {
                setSelectedMarkerData(markerData);
                setSelectedMarkerType("dock");
                setIsMarkerPopupOpen(true);
              },
            }}
            key={index}
            riseOnHover={true}
            position={[
              markerData[0].currentPos.lat + coordinateCorrection.y,
              markerData[0].currentPos.lng + coordinateCorrection.x,
            ]}
            icon={reactIcon}
          ></Marker>
        );
      });
    }
  }, [convertedDoorDockData, plantConfig]);

  //Render Production line Markers on the map
  const RenderProductionLine = useMemo(() => {
    if (plantConfig && convertedProductionLineData && enableProductionLine) {
      //Remove duplicate and filter the active production lines
      let filteredData = filterDuplicateProductionLine(
        convertedProductionLineData
      );
      //Get Plant Config data
      let { productionLines } = plantConfig.indoorMap;

      return filteredData.map((productionLine, index) => {
        //Get Correction values
        let correctionUnit = productionLines.eachItemOffset[
          productionLine.LINE_NAME
        ]
          ? productionLines.eachItemOffset[productionLine.LINE_NAME]
          : { x: 0, y: 0 };
        //Popup Data
        let productionData = productionLine;
        //Convert Lat and Lng to X and Y
        let coordinateCorrection = getLocationGroupOffset(
          productionData.currentPos,
          correctionUnit
        );
        //Marker Icon
        let reactIcon = convertReactComponentToHtmlIcon(
          <LineProductionPopup lineData={productionData} index={index} />
        );
        return (
          <Marker
            type="productionLine"
            data={productionData}
            eventHandlers={{
              click: (e) => {
                setSelectedMarkerData(productionData);
                setSelectedMarkerType("productionLine");
                setIsMarkerPopupOpen(true);
              },
            }}
            key={index}
            riseOnHover
            position={coordinateCorrection}
            icon={reactIcon}
          ></Marker>
        );
      });
    }
  }, [convertedProductionLineData, plantConfig]);

  //Render battery station Markers on the map
  const RenderBatteryStation = useMemo(() => {
    if (convertedBatteryStationObjData && enableBatteryStationObj) {
      let { batteryStations } = plantConfig.indoorMap;
      let filteredData = convertedBatteryStationObjData;
      return filteredData.map((batteryStation, index) => {
        if (batteryStation.stationName == openedBatteryStationName) {
          setSelectedMarkerData(batteryStation);
        }

        let coordinateCorrection = {
          x:
            batteryStations?.eachItemOffset?.[batteryStation.stationName]?.x ||
            0,
          y:
            batteryStations?.eachItemOffset?.[batteryStation.stationName]?.y ||
            0,
        };
        let batteryStationData = batteryStation;
        let reactIcon = convertReactComponentToHtmlIcon(
          <BatteryStationObject
            stationData={batteryStationData}
            index={index}
          />,
          "battery-station-marker"
        );
        return (
          <Marker
            type="batteryStationObj"
            data={batteryStationData}
            eventHandlers={{
              click: (e) => {
                setSelectedMarkerData(batteryStationData);
                setSelectedMarkerType("batteryStationObj");
                setIsMarkerPopupOpen(true);
              },
            }}
            key={index}
            riseOnHover
            position={[
              batteryStationData.currentPos.lat + coordinateCorrection.x,
              batteryStationData.currentPos.lng + coordinateCorrection.y,
            ]}
            icon={reactIcon}
          ></Marker>
        );
      });
    }
  }, [convertedBatteryStationObjData, enableBatteryStationObj]);

  //Render Map Object Instance with the Map Image and Bounds from the localStorage
  const RenderMap = useMemo(() => {
    const locationCode = getLocalStorageItem("locationCode");
    if (plantConfig && appConfig && mapObject) {
      let img = getPlantMapImageUrl(appConfig, locationCode, "WAREHOUSE_MAP");
      //Get Plant Config
      const { mapBg } = plantConfig.indoorMap;
      if (img && mapBg) {
        //Get Image, Image Bounds (limit), X1,Y1 , X2,Y2 coordinates of image and Image bg color
        const { bounds, coordinates, bgColor } = mapBg;
        //(X1,Y1) and (X2,Y2) data of map coordinates
        const { startX, startY, endX, endY } = coordinates;

        //Downscale start coordinate
        let startConvertedCoordinates = mapCoordinate(
          startX,
          startY,
          "map",
          bounds
        );
        let startConvertedPoints = L.point(
          startConvertedCoordinates.x,
          startConvertedCoordinates.y
        );
        let startConvertedLatLng =
          mapObject.map.target.layerPointToLatLng(startConvertedPoints);

        //Downscale end coordinate
        let endConvertedCoordinates = mapCoordinate(endX, endY, "map", bounds);
        let endConvertedPoints = L.point(
          endConvertedCoordinates.x,
          endConvertedCoordinates.y
        );
        let endConvertedLatLng =
          mapObject.map.target.layerPointToLatLng(endConvertedPoints);

        //Final Downscaled bounds for Image
        let overlayBounds = [
          [startConvertedLatLng.lat, startConvertedLatLng.lng],
          [endConvertedLatLng.lat, endConvertedLatLng.lng],
        ];
        return (
          <ImageOverlay
            url={img}
            bounds={overlayBounds}
            backgroundColor={bgColor}
          />
        );
      }
    }
  }, [mapObject, plantConfig, appConfig]);

  //Render Overlay labelled map
  const RenderLabelledMap = useMemo(() => {
    let locationCode = getLocalStorageItem("locationCode");
    if (appConfig && mapObject && enableLabelledMap) {
      let img = getPlantMapImageUrl(
        appConfig,
        locationCode,
        "WAREHOUSE_MAP_REF"
      );
      const plantDetails = getLocalStorageItem("mapOverlayDetails");
      if (img && plantDetails !== undefined) {
        const { leftBottom, rightTop } = plantDetails.bounds;
        //Image Start Coordinates
        let convertedCoordinates = mapCoordinate(
          leftBottom.lat,
          leftBottom.lng,
          "map"
        );
        let convertedPoints = L.point(
          convertedCoordinates.x,
          convertedCoordinates.y
        );
        let convertedLatLng =
          mapObject.map.target.layerPointToLatLng(convertedPoints);

        //Image End Coordinates
        let convertedCoordinates1 = mapCoordinate(
          rightTop.lat,
          rightTop.lng,
          "map"
        );
        let convertedPoints1 = L.point(
          convertedCoordinates1.x,
          convertedCoordinates1.y
        );
        let convertedLatLng1 =
          mapObject.map.target.layerPointToLatLng(convertedPoints1);

        let overlayBounds = [
          [convertedLatLng.lat, convertedLatLng.lng],
          [convertedLatLng1.lat, convertedLatLng1.lng],
        ];
        return <ImageOverlay opacity={0.5} url={img} bounds={overlayBounds} />;
      }
    }
  }, [appConfig, mapObject, enableLabelledMap]);

  //Render Destination Pointers Markers on the map
  const RenderDestinationPointers = useMemo(() => {
    if (plantConfig && enableDestinationPointers && convertedSocketData) {
      //Get Plant Config
      const { destinationPointers } = plantConfig.indoorMap;
      const { destinationCorrection, coordinateCorrection } =
        destinationPointers;

      //Filter LGV with null destination
      let filteredData = convertedSocketData.filter(
        (lgv) => lgv.destination !== null
      );
      return filteredData.map((lgv, index) => {
        let sourceAndDestination;
        let offsetObject;

        for (const [key, value] of Object.entries(destinationCorrection)) {
          if (lgv.destination.includes(String(key))) {
            offsetObject = {
              coordinateCorrection,
              value,
            };
          } else {
            let otherDestinationOffset = destinationCorrection.OTHERS;
            offsetObject = {
              coordinateCorrection,
              value: {
                x: otherDestinationOffset.x,
                y: otherDestinationOffset.y,
              },
            };
          }
        }
        sourceAndDestination = getDestinationPointerOffset(lgv, offsetObject);
        if (sourceAndDestination === undefined) {
          console.log(
            "Something wrong with this LGV's location and Destination",
            lgv
          );
        }
        if (sourceAndDestination !== undefined) {
          return (
            <div key={index} className="destination-pointers">
              <Polygon
                pathOptions={{ color: "black", zIndex: 1006 }}
                positions={sourceAndDestination}
                weight={1}
              />
            </div>
          );
        }
      });
    }
  }, [convertedSocketData, plantConfig]);

  const RenderDummyLgv = useMemo(() => {
    if (mapObject) {
      const lgvObjects = [
        // {
        //   name: "gridStart",
        //   source: [-200000, 300000],
        //   originalSource: [-150, 920],
        //   length: 39408,
        //   destination: [-190, -700],
        //   original: false,
        // },
        // {
        //   name: "gridEnd",
        //   source: [800000, -85000],
        //   originalSource: [-150, 920],
        //   length: 39408,
        //   destination: [-190, -700],
        //   original: false,
        // },
        // {
        //   name: "gridStartOld",
        //   source: [38000, 182000],
        //   originalSource: [-150, 920],
        //   length: 39408,
        //   destination: [-190, -700],
        //   original: false,
        // },
        // {
        //   name: "gridEndNew",
        //   source: [12500, 168000],
        //   originalSource: [-150, 920],
        //   length: 39408,
        //   destination: [-190, -700],
        //   original: false,
        // },
        {
          name: "gridEndNew",
          source: [12500, 168000],
          originalSource: [-1356.3403361344538, 538.1567444620252],
          length: 39408,
          destination: [-190, -700],
          original: true,
        },
      ];
      return lgvObjects.map((data, index) => {
        if (!data.original) {
          let coords = mapCoordinate(data.source[0], data.source[1], "lgv");
          let convertedPoints = L.point(coords.x, coords.y);
          let convertedLatLng =
            mapObject.map.target.layerPointToLatLng(convertedPoints);
          return (
            <Marker
              key={index}
              position={[convertedLatLng.lat, convertedLatLng.lng]}
            >
              <Popup>{`lat:${data.source[0]}, lng: ${data.source[1]}`}</Popup>
            </Marker>
          );
        } else {
          return (
            <Marker
              key={index}
              position={[data.originalSource[0], data.originalSource[1]]}
            >
              <Popup>{`lat:${data.originalSource[0]}, lng: ${data.originalSource[1]}`}</Popup>
            </Marker>
          );
        }
      });
    }
  });

  // const getPolygonCenter = (coords) => {
  //   return L.rectangle(coords).getBounds().getCenter()
  // }

  // const getPolygonLabel = (pallets) => {
  //   return L.divIcon({html: pallets});
  // }

  //Block Storage//
  const handleRectangleClick = (value) => {
    console.log(value);
  };

  const blockMockData = {
    totalPallets: 124,
    splitBlock: 12,
    direction: "S",
  };
  const colors = ["red", "green", "blue"];
  let flag = true;

  /**
   * Generate Block Coordinates, Status and Pallets
   * Combine the Status and Pallets to the list of data from DB data
   * Conditions to render the location boxes
   * 1. Total Number of position - totalPositions
   * 2. When to change the direction - positionTransition
   * 3. In Which direction to start - locationOrientation
   * 4. In which direction to render after change of direction - positionOrientation
   */
  const generateBlockCoordinates = (locationMetaData, startingPosition) => {
    let blockData = [];
    let pallet = startingPosition;
    let palletLat = pallet.x;
    let palletLng = pallet.y;
    let totalPalletsInRow = locationMetaData.positionTransition * 2;
    let totalPositions = locationMetaData.totalPositions;
    let locationOrientation = locationMetaData.locationOrientation;
    for (let index = 0; index < totalPositions; index++) {
      if (locationOrientation === "E") {
        if (index > totalPalletsInRow - 1) {
          let currentPallet = JSON.parse(JSON.stringify(locationMetaData));
          if (locationMetaData.positionOrientation === "S") {
            let reactIcon = convertReactComponentToHtmlIcon(
              <span
                style={{ backgroundColor: "green" }}
                className="block-icon-style"
              >
                {currentPallet.palletLevel}
              </span>,
              "block-storage-marker"
            );
            currentPallet.coords = [palletLat, palletLng];
            currentPallet.status = "green";
            currentPallet.pallets = locationMetaData.palletLevel;
            currentPallet.icon = reactIcon;
            blockData.push(currentPallet);
          }
          palletLng -= 4;
          if (
            index === totalPalletsInRow * 2 ||
            index === totalPalletsInRow * 3 + 1 ||
            index === totalPalletsInRow * 4 + 2 ||
            index === totalPalletsInRow * 5 + 3 ||
            index === totalPalletsInRow * 6 + 4 ||
            index === totalPalletsInRow * 7 + 5 ||
            index === totalPalletsInRow * 8 + 6
          ) {
            palletLng = pallet.y;
            palletLat += 4;
          }
        } else {
          let currentPallet = JSON.parse(JSON.stringify(locationMetaData));
          let reactIcon = convertReactComponentToHtmlIcon(
            <span
              style={{ backgroundColor: "blue" }}
              className="block-icon-style-sm"
            >
              {currentPallet.palletLevel}
            </span>,
            "block-storage-marker"
          );

          currentPallet.coords = [palletLat, palletLng];
          currentPallet.status = "blue";
          currentPallet.pallets = locationMetaData.palletLevel;
          currentPallet.icon = reactIcon;

          blockData.push(currentPallet);
          palletLng -= 4;
          if (index % 2 !== 0) {
            palletLng -= 2;
          }
          if (index === 23) {
            palletLng = pallet.y;
            palletLat += 4;
          }
        }
      } else {
        if (index > totalPalletsInRow - 1) {
          let currentPallet = JSON.parse(JSON.stringify(locationMetaData));
          if (locationMetaData.positionOrientation === "S") {
            let reactIcon = convertReactComponentToHtmlIcon(
              <span
                style={{ backgroundColor: "green" }}
                className="block-icon-style"
              >
                {currentPallet.palletLevel}
              </span>,
              "block-storage-marker"
            );
            currentPallet.coords = [palletLat, palletLng];
            currentPallet.status = "green";
            currentPallet.pallets = locationMetaData.palletLevel;
            currentPallet.icon = reactIcon;
            blockData.push(currentPallet);
          }
          palletLng += 4;
          if (
            index === totalPalletsInRow * 2 ||
            index === totalPalletsInRow * 3 + 1 ||
            index === totalPalletsInRow * 4 + 2 ||
            index === totalPalletsInRow * 5 + 3
          ) {
            palletLng = pallet.y;
            palletLat += 4;
          }
        } else {
          let currentPallet = JSON.parse(JSON.stringify(locationMetaData));
          let reactIcon = convertReactComponentToHtmlIcon(
            <span
              style={{ backgroundColor: "green" }}
              className="block-icon-style-sm"
            >
              {currentPallet.palletLevel}
            </span>,
            "block-storage-marker"
          );

          currentPallet.coords = [palletLat, palletLng];
          currentPallet.status = "blue";
          currentPallet.pallets = locationMetaData.palletLevel;
          currentPallet.icon = reactIcon;

          blockData.push(currentPallet);
          palletLng += 4;
          if (index % 2 !== 0) {
            palletLng -= 2;
          }
          if (index === 23) {
            palletLng = pallet.y;
            palletLat += 4;
          }
        }
      }
    }
    return blockData;
  };

  /**
   * Group the Raw Block Storage data into below sections
   * 1.Location Name
   * 2.Pallet Position
   * 3.Row Level
   * @param {} blockData
   * @returns locationGroup {}
   */
  const formatBlockDataStructure = (blockData, type) => {
    /**
     * Group all the locations with key as location name
     */
    let locationGroup = {};
    blockData.forEach((location) => {
      let name;
      if (type === "rack") {
        name = formatName(location.location);
      } else {
        name = location.location;
      }
      if (!locationGroup[name]) {
        locationGroup[name] = [];
      }
      locationGroup[name].push(location);
    });

    if (type === "block") {
      /**
       * Group all the pallets inside location with key as palletPosition as key
       */
      for (let location in locationGroup) {
        let palletGroup = {};
        let currentLocationIterated = locationGroup[location];
        currentLocationIterated.forEach((palletSet) => {
          let pallet = palletSet.palletPosition;
          if (!palletGroup[pallet]) {
            palletGroup[pallet] = [];
          }
          palletGroup[pallet].push(palletSet);
        });
        locationGroup[location] = palletGroup;

        /**
         * Group all the Levels inside Pallets with key as Pallet Level
         */
        for (let pallet in palletGroup) {
          let levelGroup = {};
          palletGroup[pallet].forEach((levelSet) => {
            let level = levelSet.row;
            if (!levelGroup[level]) {
              levelGroup[level] = [];
            }
            levelGroup[level].push(levelSet);
          });
          palletGroup[pallet] = levelGroup;
        }
      }
    }

    return locationGroup;
  };

  const formatBlockMetaDataStructure = (blockData) => {
    /**
     * Group all the locations with key as location name
     */
    let locationGroup = {};
    blockData.forEach((location) => {
      let name = location.location;
      if (!locationGroup[name]) {
        locationGroup[name] = location;
      }
    });
    return locationGroup;
  };

  const blockClickHandler = (blockData, type) => {
    setIsBlockRackModalOpen(true);
    setSelectedBlockRackLocationData(blockData);
    if (type === "rack") {
      setLocalStorageItem("blockRackType", true);
    } else if (type === "block") {
      setLocalStorageItem("blockRackType", false);
    }
  };

  /**
   * Generate Block Coordinates, Status and Pallets
   * Combine the Status and Pallets to the list of data from DB data
   * Conditions to render the location boxes
   * 1. Total Number of position - totalPositions
   * 2. When to change the direction - positionTransition
   * 3. In Which direction to start - locationOrientation
   * 4. In which direction to render after change of direction - positionOrientation
   */

  /**
   * Convert the grouped locations data into different list for rendering it as list in the marker
   * Split the list according to the length of the locationsList
   * @param {*} transitionPallet-number locationsList-object
   * @returns sectionList-array
   */
  const markerSectionGenerator = (
    locationsList,
    transitionPallet,
    doubleRows,
    pbcRows
  ) => {
    let sectionList = [];
    let list1 = [];
    let list2 = [];
    let list3 = [];
    let list4 = [];
    let list5 = [];
    let list6 = [];
    if (transitionPallet !== undefined) {
      Object.values(locationsList).forEach((location, index) => {
        if (transitionPallet !== null) {
          let transitionPalletLocal = transitionPallet * 2;
          if (pbcRows == 1) {
            if (index <= transitionPalletLocal - 1) {
              list1.push(location);
            }
          } else if (pbcRows == 2) {
            if (index <= transitionPalletLocal - 1) {
              list1.push(location);
            } else if (
              index > transitionPalletLocal - 1 &&
              index <= transitionPalletLocal * 2
            ) {
              list2.push(location);
            }
          }

          let totalDoubleRows = doubleRows;
          if (
            index > transitionPalletLocal - 1 &&
            index <= transitionPalletLocal * 2 - 1
          ) {
            list2.push(location);
          } else if (
            index > transitionPalletLocal * 2 &&
            index <= transitionPalletLocal * 3
          ) {
            list3.push(location);
          } else if (
            index >= transitionPalletLocal * 3 &&
            index < transitionPalletLocal * 4 &&
            totalDoubleRows > 0
          ) {
            list4.push(location);
          } else if (
            index >= transitionPalletLocal * 4 &&
            index < transitionPalletLocal * 5 &&
            totalDoubleRows > 0
          ) {
            list5.push(location);
          } else if (
            index >= transitionPalletLocal * 5 &&
            index < transitionPalletLocal * 6 &&
            totalDoubleRows > 0
          ) {
            list6.push(location);
          }
        } else {
          list1.push(location);
        }
      });
    } else {
      Object.values(locationsList).forEach((location, index) => {
        list1.push(location);
      });
    }
    if (list1.length !== 0) {
      sectionList.push(list1);
    }
    if (list2.length !== 0) {
      sectionList.push(list2);
    }
    if (list3.length !== 0) {
      sectionList.push(list3);
    }
    if (list4.length !== 0) {
      sectionList.push(list4);
    }
    if (list5.length !== 0) {
      sectionList.push(list5);
    }
    if (list6.length !== 0) {
      sectionList.push(list5);
    }

    return sectionList;
  };
  const [worker, setWorker] = useState(null);
  useEffect(() => {
    const myWorker = new Worker("worker.js");
    myWorker.onmessage = (event) => {
      console.log(event.data, "data from worker thread");
    };
    setWorker(myWorker);
    return () => {
      myWorker.terminate();
    };
  }, []);

  /**
   * Generating Location Marker with the list data
   * Create Sections with the markerSectionGenerator function
   * Columns of the marker = length of the list
   * Each List is a row of pallets
   * If length of the sectionsData = 1, the markers are single stack storage
   * else if sectionsData > 1, the markers are double stack storage
   * @param {*} locationMetaData-Object locationsList-Object
   * @returns HTML
   */

  const GenerateHTMLBlockIcon = ({ locationMetaData, locationsList, type }) => {
    if (locationMetaData.totalPositions !== 0) {
      let transitionPallet = locationMetaData.positionTransition;

      let sectionsData = markerSectionGenerator(
        locationsList,
        transitionPallet,
        locationMetaData.doubleRows,
        locationMetaData.pbcRows
      );

      //Find the Position Orientation of the selected location
      let positionOrientationOfThisLocation = getPositionOrientationOfStorage(
        type,
        sectionsData
      );

      //Reverse or keep the list straight according to the location Orientation of the selected location
      let renderList;

      if (type === "rack") {
        //Get Rendering Data according to position orientation
        renderList = getBlockRackDataWithPositionOrientation(
          positionOrientationOfThisLocation,
          sectionsData,
          "rack"
        );
        //Render Double and Single Pallet Rack
        return renderRackPallet(renderList);
      } else if (type === "block") {
        //Get Rendering Data according to position orientation
        renderList = getBlockRackDataWithPositionOrientation(
          positionOrientationOfThisLocation,
          sectionsData,
          "block"
        );
        //Check is the block Vertical
        let isLocationVertical = findIsLocationVertical(renderList);
        if (sectionsData.length === 1) {
          //Render Single Pallet Block
          return renderPreFilledSingleBlockPallet(
            renderList,
            isLocationVertical
          );
        } else {
          //Render Double Pallet Block
          return renderPreFilledDoubleBlockPallet(
            sectionsData,
            isLocationVertical,
            locationMetaData
          );
        }
      }
    }
  };

  /**
   * Returing Meta data of a single location
   * @param {*} location-object
   * @returns metaData-object
   */
  const getMetaDataOfLocation = (location, type) => {
    if (type === "block") {
      return {
        latitude: location.latitude,
        longitude: location.longitude,
        location: location.location,
        totalPositions: location.totalPositions,
        positionTransition: location.positionTransition,
        positionOrientation: location.positionOrientation,
        locationOrientation: location.locationOrientation,
        positionLength: location.positionLength,
      };
    } else {
      return {
        latitude: location.latitude,
        longitude: location.longitude,
        location: location.location,
        totalPositions: location.totalPositions,
        locationOrientation: location.locationOrientation,
        positionLength: location.positionLength,
      };
    }
  };

  /**
   * Create Markers according to the List of locations available
   * Algo:
   * 1.Iterate raw data
   * 2.Get Meta data of the location
   * 3.Generate Group of locations
   * 4.Create Marker icons with location data
   * 5.Convert the coordinates
   * 6.Plot the Markers with the coordinates and icon generated
   * @returns Markers-reactleaflet
   */

  const initiateBlockStorageWebSocket = () => {
    let locationCodeLocal = JSON.parse(localStorage.getItem("locationCode"));
    if (socket && locationCodeLocal) {
      socket.on(`${locationCodeLocal}_BLOCK_STORAGE_DETAILS`, (data) => {
        if (
          data.results.length !== 0 &&
          data.results !== "Error connecting to datasource!"
        ) {
          setBlockStorageData(data.results);
        }
      });
    }
  };

  const initiateRackStorageWebSocket = () => {
    let locationCodeLocal = JSON.parse(localStorage.getItem("locationCode"));
    if (socket && locationCodeLocal) {
      socket.on(`${locationCodeLocal}_RACK_STORAGE_DETAILS`, (data) => {
        if (
          data.results.length !== 0 &&
          data.results !== "Error connecting to datasource!"
        ) {
          setRackStorageData(data.results);
        }
      });
    }
  };

  useEffect(() => {
    initiateBlockStorageWebSocket();
    initiateRackStorageWebSocket();
  });

  function isBlockObjectValid(obj) {
    // Check if the object and index exist
    if (obj && obj[1]) {
      if (obj[1][1] && obj[1][1][0]) {
        return obj[1][1][0];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  const RenderBlockStorage = useMemo(() => {
    if (
      plantConfig &&
      isBlockRackNonLgvEnabled() &&
      mapObject &&
      Object.keys(blockStorageData).length !== 0
    ) {
      let blockData = blockStorageData;
      let formattedBlockData = formatBlockDataStructure(blockData, "block");

      return Object.values(formattedBlockData).map((block, index) => {
        let blockObject = Object.values(block);
        let validateObject = isBlockObjectValid(blockObject);
        if (validateObject) {
          //Metadata of the Location
          let metaData = validateObject;
          let {
            latitude,
            longitude,
            location,
            locationOrientation,
            positionLength,
          } = getMetaDataOfLocation(metaData, "block");
          //Convert Start
          let convertedStart;
          let convertedPoints;
          let convertedLatLng;
          let convertedPositionObj;

          //Get Block Details from Config
          const { blockStorage } = plantConfig.indoorMap;
          const {
            marker,
            bounds,
            eachItemOffset,
            eastLocationOffset,
            westLocationOffset,
          } = blockStorage;

          //New Algo ------------------------------------- Start

          //East Location Blocks
          if (locationOrientation == "E") {
            //Send the Block bounds to Coordinate Converter
            convertedStart = mapCoordinate(
              longitude - positionLength,
              latitude,
              "block",
              bounds
            );
            //Convert the X and Y to Lat and Lng
            convertedPoints = L.point(convertedStart.x, convertedStart.y);
            convertedLatLng =
              mapObject.map.target.layerPointToLatLng(convertedPoints);
            //Set the location Offset
            if (eachItemOffset[location]) {
              let offset = eachItemOffset[location];
              convertedPositionObj = getLocationGroupOffset(
                convertedLatLng,
                offset
              );
            } else {
              convertedPositionObj = getLocationGroupOffset(
                convertedLatLng,
                eastLocationOffset
              );
            }
          } else {
            //West Location Blocks
            convertedStart = mapCoordinate(
              longitude,
              latitude,
              "block",
              bounds
            );
            //Convert the X and Y to Lat and Lng
            convertedPoints = L.point(convertedStart.x, convertedStart.y);
            convertedLatLng =
              mapObject.map.target.layerPointToLatLng(convertedPoints);
            if (eachItemOffset[location]) {
              let offset = eachItemOffset[location];
              convertedPositionObj = getLocationGroupOffset(
                convertedLatLng,
                offset
              );
            } else {
              convertedPositionObj = getLocationGroupOffset(
                convertedLatLng,
                westLocationOffset
              );
            }
          }

          //New Algo ------------------------------------- End

          //Verify and remove the below code
          let className = "pallets-wrapper";
          if (
            getLocalStorageItem("locationCode") == "ALA" &&
            metaData.location?.indexOf("G.W.") > -1
          ) {
            className += " shift-left-2";
          }
          //------------------------------//

          //Set Dynamic Width and Height of Block from Config
          setBlockStorageSize(marker.size.width, marker.size.height);

          //Generate Block Icon with Data
          let reactIcon = convertReactComponentToHtmlIcon(
            <GenerateHTMLBlockIcon
              locationMetaData={metaData}
              locationsList={block}
              type="block"
            />,
            className
          );

          //Return and Render the Marker
          if (convertedPositionObj !== undefined) {
            return (
              <Marker
                eventHandlers={{
                  click: (e) => {
                    blockClickHandler(block, "block");
                  },
                }}
                key={index}
                position={convertedPositionObj}
                icon={reactIcon}
              >
                <Popup>{location}</Popup>
              </Marker>
            );
          }
        }
      });
    }
  }, [mapObject, blockStorageData, plantConfig]);

  const RenderRackStorage = useMemo(() => {
    if (
      plantConfig &&
      isBlockRackNonLgvEnabled() &&
      mapObject &&
      rackStorageData.length !== 0
    ) {
      let rackData = [...rackStorageData];
      let formattedRackData = formatBlockDataStructure(rackData, "rack");
      return Object.values(formattedRackData).map((rack, index) => {
        let metaData = Object.values(rack)[0];
        let { latitude, longitude, location } = getMetaDataOfLocation(
          metaData,
          "rack"
        );
        //Convert Start
        let convertedStart;
        let convertedPoints;
        let convertedLatLng;
        let convertedPositionObj;

        //Get Rack Details from Config
        const { rackStorage } = plantConfig.indoorMap;
        const { bounds, eachItemOffset, groupOffset } = rackStorage;

        //Send the Rack bounds to Coordinate Converter
        convertedStart = mapCoordinate(longitude, latitude, "rack", bounds);
        convertedPoints = L.point(convertedStart.x, convertedStart.y);
        convertedLatLng =
          mapObject.map.target.layerPointToLatLng(convertedPoints);

        //Convert X and Y to Lat and Lng
        if (eachItemOffset[location]) {
          let offset = eachItemOffset[location];
          convertedPositionObj = getLocationGroupOffset(
            convertedLatLng,
            offset
          );
        } else {
          convertedPositionObj = getLocationGroupOffset(
            convertedLatLng,
            groupOffset
          );
        }

        //Generate Rack Icon
        let reactIcon = convertReactComponentToHtmlIcon(
          <GenerateHTMLBlockIcon
            locationMetaData={metaData}
            locationsList={rack}
            type="rack"
          />,
          "pallets-wrapper"
        );

        //Render Rack Marker
        return (
          <Marker
            eventHandlers={{
              click: (e) => {
                blockClickHandler(rack, "rack");
              },
            }}
            key={index}
            position={convertedPositionObj}
            icon={reactIcon}
          >
            <Popup>{location}</Popup>
          </Marker>
        );
      });
    }
  }, [mapObject, rackStorageData]);

  /**
   * Generate the React leaflet Icon with the specified coordinates for Non-lgv inventory icon
   * On click of the icon, Non-LGV Inventory Modal opens up
   */
  const RenderNonLgvStorage = useMemo(() => {
    if (plantConfig && nonLgvData && isBlockRackNonLgvEnabled() && mapObject) {
      //Get Plant Config and Non LGV Settings
      const { nonLgvStorage } = plantConfig.indoorMap;
      const { marker, coordinate } = nonLgvStorage;

      //Create Marker Icon
      let reactIcon = convertReactComponentToHtmlIcon(
        <LgvIcon
          data="NON-LGV INVENTORY"
          status="white"
          className="non-lgv-icon-style"
        />,
        "non-lgv-icon",
        marker.size,
        [0, 0]
      );

      //Convert the Lat and Lng to X and Y
      let convertedStart = L.point(coordinate.x, coordinate.y, "lgv");
      let convertedLatLng =
        mapObject.map.target.layerPointToLatLng(convertedStart);
      let convertedPositionObj = getLocationGroupOffset(convertedLatLng, {
        x: 0,
        y: 0,
      });

      //Generate and return Non LGV Button
      return (
        <Marker
          key="non-lgv"
          position={convertedPositionObj}
          icon={reactIcon}
          eventHandlers={{
            click: (e) => {
              setIsNonLgvModalOpen(true);
            },
          }}
        ></Marker>
      );
    }
  }, [nonLgvData, mapObject, plantConfig]);

  //Map Custom Zoom in and Zoom out
  // const [isZoomInTriggeredOnce, setIsZoomInTriggeredOnce] = useState(false);
  useEffect(() => {
    document.getElementById("indoor-map-id-reference").style.transform =
      "scale(1.0)";
  }, []);
  const zoomIn = () => {
    let mapContainerZoom = document.getElementById("indoor-map-id-reference")
      .style.transform;
    if (mapContainerZoom === "scale(1.0)" || mapContainerZoom === "scale(1)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(1.2) translate(120px, 50px)";
    }
    if (mapContainerZoom === "scale(1.2) translate(120px, 50px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(1.4) translate(210px, 90px)";
    }
    if (mapContainerZoom === "scale(1.4) translate(210px, 90px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(1.6) translate(275px, 120px)";
    }
    if (mapContainerZoom === "scale(1.6) translate(275px, 120px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(1.8) translate(325px, 135px)";
    }
    if (mapContainerZoom === "scale(1.8) translate(325px, 135px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(2) translate(360px, 155px)";
    }

    if (mapContainerZoom === "scale(2) translate(360px, 155px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(2.2) translate(380px, 175px)";
    }
    if (mapContainerZoom === "scale(2.2) translate(380px, 175px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(2.4) translate(400px, 195px)";
    }
    if (mapContainerZoom === "scale(2.4) translate(400px, 195px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(2.6) translate(420px, 215px)";
    }
    if (mapContainerZoom === "scale(2.6) translate(420px, 215px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(2.8) translate(440px, 235px)";
    }
    if (mapContainerZoom === "scale(2.8) translate(440px, 235px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(3) translate(460px, 255px)";
    }

    if (mapContainerZoom === "scale(3) translate(460px, 255px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(3.2) translate(480px, 275px)";
    }
    if (mapContainerZoom === "scale(3.2) translate(480px, 275px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(3.4) translate(500px, 295px)";
    }
    if (mapContainerZoom === "scale(3.4) translate(500px, 295px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(3.6) translate(520px, 315px)";
    }
    if (mapContainerZoom === "scale(3.6) translate(520px, 315px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(3.8) translate(560px, 335px)";
    }
    if (mapContainerZoom === "scale(3.8) translate(560px, 335px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(4) translate(560px, 355px)";
    }

    if (mapContainerZoom === "scale(4) translate(560px, 355px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(4.2) translate(560px, 375px)";
    }
    if (mapContainerZoom === "scale(4.2) translate(560px, 375px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(4.4) translate(580px, 395px)";
    }
    if (mapContainerZoom === "scale(4.4) translate(580px, 395px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(4.6) translate(600px, 415px)";
    }
    if (mapContainerZoom === "scale(4.6) translate(600px, 415px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(4.8) translate(620px, 435px)";
    }
    if (mapContainerZoom === "scale(4.8) translate(620px, 435px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(5) translate(640px, 455px)";
    }
    setIsMapDraggable(true);
    centerMapView();
    setZoomLevel(zoomLevel + 1);
  };

  const zoomOut = () => {
    let mapContainerZoom = document.getElementById("indoor-map-id-reference")
      .style.transform;

    if (mapContainerZoom === "scale(5) translate(640px, 455px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(4.8) translate(620px, 435px)";
    }
    if (mapContainerZoom === "scale(4.8) translate(620px, 435px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(4.6) translate(600px, 415px)";
    }
    if (mapContainerZoom === "scale(4.6) translate(600px, 415px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(4.4) translate(580px, 395px)";
    }
    if (mapContainerZoom === "scale(4.4) translate(580px, 395px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(4.2) translate(560px, 375px)";
    }
    if (mapContainerZoom === "scale(4.2) translate(560px, 375px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(4) translate(560px, 355px)";
    }

    if (mapContainerZoom === "scale(4.0) translate(560px, 355px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(3.8) translate(560px, 335px)";
    }
    if (mapContainerZoom === "scale(3.8) translate(560px, 335px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(3.6) translate(520px, 315px)";
    }
    if (mapContainerZoom === "scale(3.6) translate(520px, 315px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(3.4) translate(500px, 295px)";
    }
    if (mapContainerZoom === "scale(3.4) translate(500px, 295px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(3.2) translate(480px, 275px)";
    }
    if (mapContainerZoom === "scale(3.2) translate(480px, 275px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(3) translate(460px, 255px)";
    }

    if (mapContainerZoom === "scale(3) translate(460px, 255px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(2.8) translate(440px, 235px)";
    }
    if (mapContainerZoom === "scale(2.8) translate(440px, 235px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(2.6) translate(420px, 125px)";
    }
    if (mapContainerZoom === "scale(2.6) translate(420px, 125px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(2.4) translate(400px, 195px)";
    }
    if (mapContainerZoom === "scale(2.4) translate(400px, 195px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(2.2) translate(380px, 175px)";
    }
    if (mapContainerZoom === "scale(2.2) translate(380px, 175px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(2) translate(360px, 155px)";
    }

    if (mapContainerZoom === "scale(2) translate(360px, 155px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(1.8) translate(325px, 135px)";
    }
    if (mapContainerZoom === "scale(1.8) translate(325px, 135px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(1.6) translate(275px, 120px)";
    }
    if (mapContainerZoom === "scale(1.6) translate(275px, 120px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(1.4) translate(210px, 90px)";
    }
    if (mapContainerZoom === "scale(1.4) translate(210px, 90px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(1.2) translate(120px, 50px)";
    }
    if (mapContainerZoom === "scale(1.2) translate(120px, 50px)") {
      document.getElementById("indoor-map-id-reference").style.transform =
        "scale(1.0)";
    }
    setIsMapDraggable(true);
    centerMapView();
    setZoomLevel(zoomLevel - 1);
  };

  const resetZoom = () => {
    document.getElementById("indoor-map-id-reference").style.transform =
      "scale(1.0)";
  };

  const checkZoomInLimit = () => {
    if (
      document.getElementById("indoor-map-id-reference") &&
      document.querySelector(".map-container")
    ) {
      if (
        document.getElementById("indoor-map-id-reference").style.transform ===
        "scale(5) translate(640px, 455px)"
      ) {
        document.querySelector(".map-container").style.cursor = "default";
        return true;
      } else {
        document.querySelector(".map-container").style.cursor = "grab";
        return false;
      }
    }
  };

  const checkZoomOutLimit = () => {
    if (
      document.getElementById("indoor-map-id-reference") &&
      document.querySelector(".map-container")
    ) {
      if (
        document.getElementById("indoor-map-id-reference").style.transform ===
        "scale(1)"
      ) {
        document.querySelector(".map-container").style.cursor = "default";
        return true;
      } else {
        document.querySelector(".map-container").style.cursor = "grab";
        return false;
      }
    }
  };

  useEffect(() => {
    const slider = document.querySelector("#map-container-id");
    let mouseDown = false;
    let startX, scrollLeft, startY, scrollTop;

    let startDragging = function (e) {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      startY = e.pageY - slider.offsetTop;
      scrollTop = slider.scrollTop;
      scrollLeft = slider.scrollLeft;
    };
    let stopDragging = function (event) {
      mouseDown = false;
    };

    slider.addEventListener("mousemove", (e) => {
      if (!isMapDragEnable) {
        return;
      }
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    });

    slider.addEventListener("mousemove", (e) => {
      if (!isMapDragEnable) {
        return;
      }
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const y = e.pageY - slider.offsetTop;
      const scroll = y - startY;
      slider.scrollTop = scrollTop - scroll;
    });

    slider.addEventListener("mousedown", startDragging, false);
    slider.addEventListener("mouseup", stopDragging, false);
    slider.addEventListener("mouseleave", stopDragging, false);
  }, []);

  //ZOOM IN and OUT
  // const getCursorPosition = (event) => {
  //   let cursorX = event.clientX;
  //   let cursorY = event.clientY;
  //   // setZoomInPressedCount(zoomInPressedCount + 1);
  //   zoomInHotPress({x:cursorX, y:cursorY});
  // }

  // useEffect(() => {
  //   let mapContainer = document.getElementById('map-container-id');
  //   mapContainer.addEventListener('ondblclick', (event) => {
  //     const rect = this.getBoundingClientRect();
  //     console.log(rect);
  //     const centerX = rect.left + rect.width / 2;
  //     const centerY = rect.top + rect.height / 2;
  //     const mouseX = event.clientX;
  //     const mouseY = event.clientY;

  //     const distanceX = mouseX - centerX;
  //     const distanceY = mouseY - centerY;

  //     const scale = Math.max(0.5, 1 + (Math.abs(distanceX) + Math.abs(distanceY)) / 1000); // Adjust this coefficient for desired zoom factor

  //     this.style.transform = `scale(${scale})`;
  //   });
  // }, [window])

  // window.addEventListener('DOMContentLoaded', (event) => {
  //   let mapContainer = document.getElementById('map-container-id');
  //   mapContainer.addEventListener('ondblclick', (event) => {
  //     const rect = this.getBoundingClientRect();
  //     console.log(rect);
  //     const centerX = rect.left + rect.width / 2;
  //     const centerY = rect.top + rect.height / 2;
  //     const mouseX = event.clientX;
  //     const mouseY = event.clientY;

  //     const distanceX = mouseX - centerX;
  //     const distanceY = mouseY - centerY;

  //     const scale = Math.max(0.5, 1 + (Math.abs(distanceX) + Math.abs(distanceY)) / 1000); // Adjust this coefficient for desired zoom factor

  //     this.style.transform = `scale(${scale})`;
  //   });
  // });

  //LGV Modal Handlers
  const minimizeLgvsHandler = () => {
    setIsLgvListOpened(!isLgvListOpened);
  };

  const closeParticularLgvModal = (item) => {
    let localLgvList = minimizedWebObjectsList.lgv;
    let indexOfItemToDelete = localLgvList.indexOf(item);
    localLgvList.splice(indexOfItemToDelete, 1);
    setMinimizedWebObjectsList((minimizedWebObjectsList) => ({
      ...minimizedWebObjectsList,
      lgv: localLgvList,
    }));
    if (localLgvList.length == 0) {
      setIsLgvListOpened(false);
    }
  };

  const maximizeLgvModal = (item) => {
    setSelectedMarkerData(item);
    setSelectedMarkerType("lgv");
    setIsMarkerPopupOpen(true);
  };

  //Dock Door Handlers
  const minimizeDocksHandler = () => {
    setIsDockListOpened(!isDockListOpened);
  };

  const closeParticularDockModal = (item) => {
    let localDocksList = minimizedWebObjectsList.docks;
    let indexOfItemToDelete = localDocksList.indexOf(item);
    localDocksList.splice(indexOfItemToDelete, 1);
    setMinimizedWebObjectsList((minimizedWebObjectsList) => ({
      ...minimizedWebObjectsList,
      docks: localDocksList,
    }));
    if (localDocksList.length == 0) {
      setIsDockListOpened(false);
    }
  };

  const maximizeDockModal = (item) => {
    setSelectedMarkerData(item);
    setSelectedMarkerType("dock");
    setIsMarkerPopupOpen(true);
  };

  //Production Line Handlers
  const minimizeProductionLineHandler = () => {
    setIsProductionLineListOpened(!isProductionLineListOpened);
  };

  const closeParticularProductionLineModal = (item) => {
    let localProductionLineList = minimizedWebObjectsList.productionLine;
    let indexOfItemToDelete = localProductionLineList.indexOf(item);
    localProductionLineList.splice(indexOfItemToDelete, 1);
    setMinimizedWebObjectsList((minimizedWebObjectsList) => ({
      ...minimizedWebObjectsList,
      productionLine: localProductionLineList,
    }));
    if (localProductionLineList.length == 0) {
      setIsProductionLineListOpened(false);
    }
  };

  const maximizeProductionLineModal = (item) => {
    setSelectedMarkerData(item);
    setSelectedMarkerType("productionLine");
    setIsMarkerPopupOpen(true);
  };

  const closeAllMinimizedModals = (type) => {
    if (type == "lgv") {
      setMinimizedWebObjectsList((minimizedWebObjectsList) => ({
        ...minimizedWebObjectsList,
        lgv: [],
      }));
      minimizeLgvsHandler();
    } else if (type == "dock") {
      setMinimizedWebObjectsList((minimizedWebObjectsList) => ({
        ...minimizedWebObjectsList,
        docks: [],
      }));
      minimizeDocksHandler();
    } else if (type == "productionLine") {
      setMinimizedWebObjectsList((minimizedWebObjectsList) => ({
        ...minimizedWebObjectsList,
        productionLine: [],
      }));
      minimizeProductionLineHandler();
    } else if (type == "followingLgv") {
      setFollowingLgvList([]);
      minimizeFollowingLGVsHandler();
    }
  };

  const renderMinimizeWebObjects = useMemo(() => {
    return (
      <div className="minimized-objects-wrapper">
        <div className="minimized-objects-container">
          {/* LGV Modals */}
          <div className="minimized-objects-row-wrapper">
            {isLgvListOpened ? (
              <div className="minimized-objects-expanded-view minimized-objects-expanded-view-lgv-width">
                {/* Header */}
                <div className="minimized-objects-expanded-view-header">
                  <span className="minimized-objects-expanded-view-header-title">
                    LGVs
                  </span>
                  <span
                    onClick={() => closeAllMinimizedModals("lgv")}
                    className="minimized-objects-expanded-view-header-close"
                  >
                    Close All
                  </span>
                </div>
                {/* Body */}
                <div className="minimized-objects-expanded-view-body">
                  {minimizedWebObjectsList &&
                    minimizedWebObjectsList.lgv.map((item, index) => {
                      return (
                        <div
                          onClick={() => maximizeLgvModal(item)}
                          key={index}
                          className="minimized-objects-expanded-view-body-item"
                        >
                          <span>LGV {item.vehicleNumber}</span>
                          <div className="object-items-actions-buttons-container">
                            <p className="minimize-card-container-toggler">
                              <img src={expand} />
                            </p>
                            <p
                              onClick={() => closeParticularLgvModal(item)}
                              className="minimize-card-container-toggler margin-left-5"
                            >
                              <img
                                className="minimize-card-img"
                                src={closeBtn}
                              />
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : null}
            {minimizedWebObjectsList.lgv.length !== 0 ? (
              <div
                onClick={() => minimizeLgvsHandler()}
                className="minimize-card-container"
              >
                <p className="minimize-card-container-title">LGVs</p>
                <div className="minimize-card-container-count-container">
                  <p className="minimize-card-container-count">
                    {minimizedWebObjectsList.lgv.length}
                  </p>
                </div>
                <p className="minimize-card-container-toggler">
                  <img
                    className={`${
                      isLgvListOpened
                        ? "rotate-down-arrow-on-click"
                        : "rotate-up-arrow-on-click"
                    }`}
                    src={expand}
                  />
                </p>
              </div>
            ) : null}
          </div>

          {/* Dock Door Modals */}
          <div className="minimized-objects-row-wrapper">
            {isDockListOpened ? (
              <div className="minimized-objects-expanded-view minimized-objects-expanded-view-dock-width">
                <div className="minimized-objects-expanded-view-header">
                  <span className="minimized-objects-expanded-view-header-title">
                    Dock Doors
                  </span>
                  <span
                    onClick={() => closeAllMinimizedModals("dock")}
                    className="minimized-objects-expanded-view-header-close"
                  >
                    Close All
                  </span>
                </div>
                <div className="minimized-objects-expanded-view-body">
                  {minimizedWebObjectsList &&
                    minimizedWebObjectsList.docks.map((item, index) => {
                      return (
                        <div
                          onClick={() => maximizeDockModal(item)}
                          key={index}
                          className="minimized-objects-expanded-view-body-item"
                        >
                          <span>Dock Door {item[0].DOCK.split(".")[1]}</span>
                          <div className="object-items-actions-buttons-container">
                            <p className="minimize-card-container-toggler">
                              <img src={expand} />
                            </p>
                            <p
                              onClick={() => closeParticularDockModal(item)}
                              className="minimize-card-container-toggler margin-left-5"
                            >
                              <img
                                className="minimize-card-img"
                                src={closeBtn}
                              />
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : null}
            {minimizedWebObjectsList.docks.length !== 0 ? (
              <div
                onClick={() => minimizeDocksHandler()}
                className="minimize-card-container"
              >
                <p className="minimize-card-container-title">Dock Doors</p>
                <div className="minimize-card-container-count-container">
                  <p className="minimize-card-container-count">
                    {minimizedWebObjectsList.docks.length}
                  </p>
                </div>
                <p className="minimize-card-container-toggler">
                  <img
                    className={`${
                      isDockListOpened
                        ? "rotate-down-arrow-on-click"
                        : "rotate-up-arrow-on-click"
                    }`}
                    src={expand}
                  />
                </p>
              </div>
            ) : null}
          </div>

          {/* Production Line Modals */}
          <div className="minimized-objects-row-wrapper">
            {isProductionLineListOpened ? (
              <div className="minimized-objects-expanded-view minimized-objects-expanded-view-production-line-width">
                <div className="minimized-objects-expanded-view-header">
                  <span className="minimized-objects-expanded-view-header-title">
                    Production Lines
                  </span>
                  <span
                    onClick={() => closeAllMinimizedModals("productionLine")}
                    className="minimized-objects-expanded-view-header-close"
                  >
                    Close All
                  </span>
                </div>
                <div className="minimized-objects-expanded-view-body">
                  {minimizedWebObjectsList &&
                    minimizedWebObjectsList.productionLine.map(
                      (item, index) => {
                        return (
                          <div
                            onClick={() => maximizeProductionLineModal(item)}
                            key={index}
                            className="minimized-objects-expanded-view-body-item"
                          >
                            <span>Production Line {item.LINE_NAME}</span>
                            <div className="object-items-actions-buttons-container">
                              <p className="minimize-card-container-toggler">
                                <img src={expand} />
                              </p>
                              <p
                                onClick={() =>
                                  closeParticularProductionLineModal(item)
                                }
                                className="minimize-card-container-toggler margin-left-5"
                              >
                                <img
                                  className="minimize-card-img"
                                  src={closeBtn}
                                />
                              </p>
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
              </div>
            ) : null}
            {minimizedWebObjectsList.productionLine.length !== 0 ? (
              <div
                onClick={() => minimizeProductionLineHandler()}
                className="minimize-card-container"
              >
                <p className="minimize-card-container-title">
                  Production Lines
                </p>
                <div className="minimize-card-container-count-container">
                  <p className="minimize-card-container-count">
                    {minimizedWebObjectsList.productionLine.length}
                  </p>
                </div>
                <p className="minimize-card-container-toggler">
                  <img
                    className={`${
                      isProductionLineListOpened
                        ? "rotate-down-arrow-on-click"
                        : "rotate-up-arrow-on-click"
                    }`}
                    src={expand}
                  />
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }, [
    minimizedWebObjectsList,
    isLgvListOpened,
    isDockListOpened,
    isProductionLineListOpened,
  ]);

  //Following LGVs
  const minimizeFollowingLGVsHandler = () => {
    setIsLgvFollowingListOpened(!isLgvFollowingListOpened);
  };

  const unFollowParticularLgv = (item) => {
    let localFollowingLgvList = followingLgvList;

    let indexOfItemToDelete = localFollowingLgvList.indexOf(item);
    localFollowingLgvList.splice(indexOfItemToDelete, 1);

    setFollowingLgvList(JSON.parse(JSON.stringify(localFollowingLgvList)));
  };

  //Follow LGVs
  const renderFollowingLgvObjects = useMemo(() => {
    return (
      <div className="followed-objects-wrapper">
        <div className="followed-objects-container">
          <div className="followed-objects-row-wrapper">
            <div className="minimized-objects-expanded-view-body">
              {isLgvFollowingListOpened ? (
                <div className="minimized-objects-expanded-view minimized-objects-expanded-view-production-line-width minimized-objects-follow-lgv-extra-bottom-space">
                  <div className="minimized-objects-expanded-view-header">
                    <span className="minimized-objects-expanded-view-header-title">
                      LGVs Following
                    </span>
                    <span
                      onClick={() => closeAllMinimizedModals("followingLgv")}
                      className="minimized-objects-expanded-view-header-close"
                    >
                      UnFollow All
                    </span>
                  </div>
                  <div className="minimized-objects-expanded-view-body">
                    {followingLgvList &&
                      followingLgvList.map((item, index) => {
                        return (
                          <div
                            onClick={() => maximizeLgvModal(item)}
                            key={index}
                            className="minimized-objects-expanded-view-body-item"
                          >
                            <span>LGV {item.vehicleNumber}</span>
                            <div className="object-items-actions-buttons-container">
                              <p
                                onClick={() => unFollowParticularLgv(item)}
                                className="following-lgv-card-container-toggler"
                              >
                                UnFollow
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              ) : null}
              {followingLgvList.length !== 0 ? (
                <div
                  onClick={() => minimizeFollowingLGVsHandler()}
                  className="minimize-card-container"
                >
                  <p className="minimize-card-container-title">
                    LGVs Following
                  </p>
                  <div className="minimize-card-container-count-container extra-space-badge">
                    <p className="minimize-card-container-count">
                      {followingLgvList.length} / 5
                    </p>
                  </div>
                  <p className="minimize-card-container-toggler">
                    {/* {isLgvFollowingListOpened ? (
                  ) : null} */}
                    <img
                      className={`rotate-arrow-on-click ${
                        isLgvFollowingListOpened
                          ? "rotate-down-arrow-on-click"
                          : "rotate-up-arrow-on-click"
                      }`}
                      src={expand}
                    />
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }, [followingLgvList, isLgvFollowingListOpened]);

  return (
    <div
      className={`map-container-wrapper plant-${getLocalStorageItem(
        "locationCode"
      )}`}
    >
      <div
        onMouseDown={(event) => onDragging(event)}
        onMouseUp={(event) => onDraggingEnd(event)}
        id="map-container-id"
        className="map-container"
      >
        {mapLoader ? (
          <div className="loader">
            <h4>Loading Warehouse Map...</h4>
            <div className="spinner"></div>
          </div>
        ) : null}
        <MapContainer
          id="indoor-map-id-reference"
          style={{
            height: "100%",
            width: "100%",
            borderRadius: "10px",
          }}
          center={[0, 0]}
          zoom={0}
          maxZoom={6}
          ref={mapRef}
          maxBoundsViscosity={1.0}
          crs={L.CRS.Simple}
          attributionControl={false}
          scrollWheelZoom={false}
          whenReady={(map) => mapWhenReadyHandler(map)}
          // dragging={isMapDraggable}
          zoomControl={false}
          disableDoubleClickZoom={true}
        >
          {RenderMap}
          {RenderLgv}
          {/* {RenderLabelledMap} */}
          {/* {RenderDummyLgv} */}
          {RenderDestinationPointers}
          {RenderDockDoor}
          {RenderProductionLine}
          {RenderBatteryStation}
          {RenderBlockStorage}
          {RenderRackStorage}
          {RenderNonLgvStorage}
        </MapContainer>
      </div>
      {dockDoorViewIsOpen ? <DockDoor /> : null}

      {minimizedWebObjectsList.lgv.length !== 0 ||
      minimizedWebObjectsList.docks.length !== 0 ||
      minimizedWebObjectsList.productionLine.length !== 0
        ? renderMinimizeWebObjects
        : null}

      {followingLgvList.length !== 0 ? renderFollowingLgvObjects : null}

      <div className="zoom-control-center">
        <button
          id="map-zoom-in-btn"
          className="zoom-controls zoom-in-control"
          disabled={checkZoomInLimit()}
          onClick={() => zoomIn()}
        >
          <img alt="zoom-in-icon" src={zoomInIcon} />
        </button>
        <button
          id="map-zoom-out-btn"
          className="zoom-controls zoom-out-control"
          disabled={checkZoomOutLimit()}
          onClick={() => zoomOut()}
        >
          <img
            alt="zoom-out-icon"
            style={{ opacity: checkZoomOutLimit() === true ? "0.5" : "1" }}
            src={zoomOutIcon}
          />
        </button>
        <button
          className="zoom-controls zoom-reset-controls"
          disabled={checkZoomOutLimit()}
          onClick={() => resetZoom()}
        >
          <img
            alt="zoom-refresh-icon"
            style={{ opacity: checkZoomOutLimit() === true ? "0.5" : "1" }}
            src={zoomRefreshIcon}
          />
        </button>
      </div>
    </div>
  );
};

export default IndoorMap;
